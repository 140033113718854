export default `
## RadioPublic Paid Listens Program Terms

Updated April 25, 2019

These terms (the “Paid Listens Program Terms”) govern RadioPublic's (“RadioPublic”) Paid Listens Program (“Paid Listens Program”).

By participating in the Paid Listens Program, you agree to these Paid Listens Program Terms, as well as RadioPublic’s Terms of Service, Privacy Policy, and all other terms and conditions that generally apply to the RadioPublic website and mobile applications (all of these together are the “Terms”).

Please read the Paid Listens Program Terms carefully. If you don’t understand them, or don’t accept any part of them, then you can’t participate in the Paid Listens Program. Your online acceptance of RadioPublic’s Terms form a binding agreement between you and RadioPublic and show your intent to be bound by them.

### What constitutes a Paid Listen

We define a Paid Listen as a listener hearing at least 60% or 30 minutes of an episode, whichever is shorter. Only listens in the native RadioPublic mobile apps for iOS and Android count towards your Paid Listens (RadioPublic reserves the right to introduce new listening services eligible for Paid Listens). Multiple listens to a given episode by the same user’s device/account will count as a single listen.

### Content rights and responsibilities

You own the rights to the content you create and make available on RadioPublic.

By participating in Paid Listens on RadioPublic, you agree that RadioPublic may enable advertising on RadioPublic’s applications (including our iOS, Android, websites, embed players) including in connection with the display of your content or other information.

We will never license or sell your content to third parties without your explicit permission.

You are responsible for the content you are publishing. This means you assume all risks related to it, including someone else’s reliance on its accuracy, or claims relating to intellectual property or other legal rights.

By making your content available on RadioPublic, you represent that doing so doesn’t conflict with any other agreement you’ve made.

You may only participate in Paid Listens if you have sufficient rights to make commercial use of the content in your podcast.

By making content available that you didn’t create on RadioPublic, you are representing that you have the right to do so. For example, you are posting a work that’s in the public domain, used under license (including a free license, such as Creative Commons), or a fair use.

The consequences of participating in Paid Listens when you lack the rights to do so or failing to provide adequate documentation upon request include withholding of revenue, suspension or termination from the Paid Listens Program, suspension or termination of your RadioPublic account, and removal of your podcast from RadioPublic’s platform entirely.

You are not entitled to earn or receive any revenue in connection with your content in any of the following circumstances: (1) if one or more third parties claim rights to any element(s) of your content,  or (2) if your participation in the RadioPublic Paid Listens Program is suspended or terminated. If either of these occur, RadioPublic will use reasonable efforts to notify you.

These Terms are not legal advice. If you plan to monetize a podcast containing any content you did not create, you may want to talk to a lawyer first to ensure you own all rights required to do so.

We can remove any content you make available on RadioPublic for any reason.

You can remove any of your content at any time by contacting us at help@radiopublic.com. Processing the removal may take a little time, but we’ll do it as quickly as possible. We may keep backup copies of your deleted feeds on our servers for up to 14 days after you delete it.

### No cheating

Podcasts and accounts that attempt to game our system with artificially inflated listening or any other disingenuous interactions will be removed from the RadioPublic Paid Listens Program, and, depending on severity, may be subject to suspension from the platform.

### Prohibited conduct

The following are not eligible for inclusion in Paid Listens — podcasts or episodes that:

* Advocate or promote intolerance or prejudice against individuals or groups, including the use of scientific or pseudo-scientific claims to pathologize, dehumanize, or disempower others
* Gratuitously use profanity or excessively graphic language for the purposes of shock or incitement
* Glorify, celebrate, downplay, or trivialize violence, suffering, abuse, or deaths
* Exist mainly to target, shame, intimidate or harass identified, identifiable or anonymous people
* Dox someone, including by exposing personal information or aggregating of public information
* Review businesses or products in an inflammatory or abusive manner
* Consist of unsubstantiated, unverified, or misleading claims and reporting

RadioPublic reserves the right to review podcasts, episodes, and accounts at any time and stop payment for any episode that violates these Terms or RadioPublic’s general rules. In deciding whether something has violated the Terms, we will take into account things like the context, newsworthiness, and nature of the posted information and applicable privacy laws. Repeated violations of the Terms will result in permanent ineligibility to participate in the program.

### Reporting content

If you see content that you believe violates these rules and guidelines, please alert us at help@radiopublic.com for review.

### Termination

RadioPublic reserves the right to disqualify a podcast or a podcast episode at any time, which will end that podcast or episode’s ability to generate revenue. RadioPublic reserves the right in its sole discretion to terminate your participation in the Paid Listens Program at any time.

### Account and Payment Method Requirement

In order to receive payment of any revenues under the Paid Listens Program, you must have a bank account or other payment mechanism available as designated by RadioPublic. RadioPublic does not owe you for any revenues that may be associated with your content during any period in which you do not have a confirmed payment method.

### Changes or Discontinuation of Paid Listens Program

RadioPublic reserves the right to modify, suspend or discontinue the Paid Listens Program with or without notice to you. RadioPublic will not be liable to you or any third party for any such modification, suspension, or termination. RadioPublic may change these Paid Listens Program Terms from time to time so we encourage you to periodically review this page for most up-to-date version. If you refuse to accept the updated Paid Listens Program Service Terms then RadioPublic reserves its right to suspend or terminate your participation in the Paid Listens Program.

### Communications

By using the Paid Listens Program, you consent to receiving communications from us related to it, including marketing communications such as newsletters, special offers, promotional announcements, and customer surveys, to your registered email address or via other methods.

### Taxes

If required by law, you are responsible for reporting and paying any applicable taxes in connection with your participation in the Paid Listens Program. Such taxes may include duties, customs fees, or other taxes (other than income tax), along with any related penalties or interest.

### Governing Law and Forum

The governing law, mandatory forum, and dispute resolution provisions of RadioPublic’s Terms of Service also apply to these Paid Listens Program Terms and any dispute arising under them.

### Miscellaneous

You participate in the Paid Listens Program at your own risk. You agree that you are solely responsible for your actions in connection with the Paid Listens Program, any breach of your obligations under the Paid Listens Program Terms, and for the consequences of any such breach.

Any capitalized terms used but not defined in these Paid Listens Program Terms have the meanings given to them in RadioPublic’s Terms of Service. Except as modified by these Terms, RadioPublic’s Terms of Service remain in effect. RadioPublic’s right to modify or revise the Terms of Service also applies to these Paid Listens Program Terms.

### Contact Us

If you have questions or feedback about the Paid Listens Program, you can email at us at help@radiopublic.com
`;
