import React from 'react';

export default function EmbedPlayer(props) {
  return (
    <iframe
      sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups allow-forms"
      scrolling="no"
      width="100%"
      height="185"
      frameBorder="0"
      src={`https://embed.radiopublic.com/e?if=${encodeURIComponent(props.programId)}`}
    />
  );
}
