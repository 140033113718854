import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Analytics from 'react-ga';
import qs from 'qs';
import SignupPage from '../SignupPage';
import Page from '../../components/Page';
import SearchableEmbedPlayer from '../../components/SearchableEmbedPlayer';
import CallToActionSectionMultiline from '../../components/CallToActionSectionMultiline';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import { STORAGE_KEY_INCOMING_FEED_URL } from '../../utils/LocalStorageKeys';
import pushkinImage from './assets/pushkin.png';
import seanImage from './assets/seanhoward.png';
import emojiBrain from './assets/emoji_brain.svg';
import emojiConfetti from './assets/emoji_confetti.svg';
import emojiCursor from './assets/emoji_cursor.svg';
import emojiEyes from './assets/emoji_eyes.svg';
import emojiGear from './assets/emoji_gear.svg';
import emojiGlobe from './assets/emoji_globe.svg';
import emojiGraph1 from './assets/emoji_graph1.svg';
import emojiGraph2 from './assets/emoji_graph2.svg';
import emojiLightning from './assets/emoji_lightning.svg';
import emojiLinks from './assets/emoji_links.svg';
import emojiMagnifier from './assets/emoji_magnifier.svg';
import emojiMegaphone from './assets/emoji_megaphone.svg';
import emojiMuscle from './assets/emoji_muscle.svg';
import emojiPerson from './assets/emoji_person.svg';
import emojiPhone from './assets/emoji_phone.svg';
import emojiPlay from './assets/emoji_play.svg';
import emojiRss from './assets/emoji-rss.svg';
import emojiShare from './assets/emoji_share.svg';
import emojiStars from './assets/emoji_stars.svg';
import emojiTarget from './assets/emoji_target.svg';
import emojiThumb from './assets/emoji-thumb.svg';
import emojiUpdate from './assets/emoji_update.svg';
import logoUSAT from './assets/logo-usa.svg';
import logoET from './assets/logo-et.svg';
import logoVice from './assets/logo-vice.svg';
import logoNYT from './assets/logo-nyt.svg';
import logoWGBH from './assets/logo-wgbh.svg';
import logoWaPo from './assets/logo-wapo.svg';
import logoPRX from './assets/logo-prx.svg';
import logoReddit from './assets/logo-reddit.svg';
import logoMedium from './assets/logo-medium.svg';
import logoWordPress from './assets/logo-wordpress.svg';
import shareSampleImage from './assets/share_sample.jpg';
import imageBranding from './assets/image-branding.svg';
import imageMarketing from './assets/image-marketing.svg';
import imageCompatible from './assets/compatible.png';
import imageCustomizable from './assets/customizable.png';
import imageFlexible from './assets/flexible.png';
import imageRipples from './assets/bg_ripples.png';
import imageCrowd from './assets/bg-crowd.png';
import './styles.scss';

const DEFAULT_EMBED_PLAYER_FEED = 'https://radiopublic.com/civilized-8gK709';

export default class HomePage extends Component<RouteComponentProps> {
  state = {
    initialFeedUrl: ''
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Analytics.pageview('/');
    this._parseQueryStringParams();
  }

  render() {
    return (
      <Page className="homepage-v2" title="Helping podcasters grow their audience and business with RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={imageCrowd} justifyBackground="center" aspectRatio="2/1">
          <h1>Savvy podcasters and digital marketers use RadioPublic to promote podcasts</h1>
          <p>Grow your podcast with free tools trusted by thousands of podcasters</p>
          <div>
            <Link to="/signup" className="button-blue">
              Try for free
            </Link>
            <Link to="/pricing" className="button-white">
              Compare plans
            </Link>
          </div>
        </PanelWithBackdrop>
        <section className="segment-section-grid-tall">
          <div className="embed">
            <h4 className="grid-tall-heading">Better marketing means more listeners</h4>
            <p className="section-subheading grid-tall-body">
              Pssst! Here’s the most powerful podcast promotion tool and you can start using it right now, and for free: the RadioPublic podcast embed
              player.
              <br />
              <br />
              <b>Why is a web player such an important marketing tool?</b>
            </p>
            {this._renderGridSegment('spot-1', emojiEyes, 'Discovery', '73% of people who listen to podcasts discover new shows via the web.')}
            {this._renderGridSegment('spot-2', emojiStars, 'Increased engagement', 'People who click play stick around on your website longer.')}
            {this._renderGridSegment(
              'spot-3',
              emojiMegaphone,
              'Free marketing',
              'Publishers and reporters love using podcast embeds in their articles.'
            )}
            <div className="grid-tall-side-align-body embed-group">
              <div>
                <p>Getting your own embed is simple: search for your show or just paste the link to your podcast’s RSS feed or iTunes link.</p>
                <SearchableEmbedPlayer initialFeedUrl={this.state.initialFeedUrl} />
                {this.state.initialFeedUrl && (
                  <p>
                    <img src={emojiThumb} /> To save you time, we’ve already fetched and built your embed player—yes, that’s how simple it is. Just
                    press play.
                  </p>
                )}
              </div>
              <p>
                You can start using this embed right now on thousands of websites. (Seriously. Right now. Someone could be pressing play on your
                podcast <i>right now</i>.) All you need is a free RadioPublic account.
              </p>
              <Link className="button-blue" to="/signup">
                Get the embed code for your player
              </Link>
            </div>
          </div>
        </section>
        <section className="segment-section-grid-squat red" style={{ backgroundImage: `url(${imageRipples})` }}>
          <div>
            <h4 className="grid-squat-heading">All RadioPublic plans come with a podcast web player and customizable landing pages</h4>
            <p className="section-subheading grid-squat-body">
              Fit your podcast seamlessly into your website design. Use your embed player in articles all over the web—like when you write a blog
              post, another podcaster cross-promotes your show, or someone writes about your podcast.
            </p>
            {this._renderGridImage('spot-1', imageFlexible)}
            {this._renderGridImage('spot-2', imageCustomizable)}
            {this._renderGridImage('spot-3', imageCompatible)}
          </div>
        </section>
        <section className="segment-section-grid-marketing">
          <div>
            <img className="marketing-image grid-tall-lead-image" src={imageMarketing} />
            <h4 className="grid-tall-heading">A suite of powerful podcast marketing tools</h4>
            <p className="section-subheading grid-tall-body">
              <Link to={'/signup'}>Start with an embed, end up with a listener</Link>
              <br />
              <br />
              The first and all-important step has been accomplished: someone’s pressed play on your podcast! And now, it’s time to keep those
              listeners around, with seamless transitions to listen on the go, subscribe to the show, and ongoing engagement opportunities.
            </p>
            <div className="grid-tall-side-align-bottom marketing-segments">
              {this._renderGridSegmentSmall(
                'A link for all occasions (and devices)',
                'RadioPublic gives you one magical link that works anywhere in the world and with all platforms. And all your downloads show up in your podcast listening stats.'
              )}
              {this._renderGridSegmentSmall(
                'Mobile optimized landing pages',
                'Your mobile-friendly landing pages for your show and all your episodes work wherever and whenever someone wants to listen. '
              )}
              {this._renderGridSegmentSmall(
                'Powerful conversion tools',
                'Deepen your listener relationships with calls to action to subscribe to your newsletter, complete a survey, or buy merch.'
              )}
            </div>
          </div>
        </section>
        <section className="segment-section-grid-squat">
          <div>
            <h4 className="grid-squat-heading">Tools that work everywhere</h4>
            {this._renderGridSegment(
              'spot-1',
              emojiPhone,
              'Compatible with all listening platforms',
              'Apple Podcasts, Spotify, Google Podcasts, Anchor, Stitcher, Pocket Casts, you pick the podcast app, and RadioPublic marketing tools work with every single one of them.'
            )}
            {this._renderGridSegment(
              'spot-2',
              emojiPerson,
              'No tech team or web guy needed!',
              'There are no complicated steps or approvals from managers and your IT department when it comes to marketing your podcast.'
            )}
            {this._renderGridSegment(
              'spot-3',
              emojiGear,
              'Works with all hosting companies',
              'We like it when things work together. RadioPublic is designed to work with existing podcasting tools, not instead of. You keep your current podcast hosting provider—Libsyn, Simplecast, Podbean, Soundcloud, Anchor… you name it, and we work with it.'
            )}
          </div>
        </section>
        <section className="segment-section-logos">
          <h4>Designed to fit the needs of indies and larger teams</h4>
          <p className="section-subheading">Yeah, it’s that section of “look who we work with!” logos</p>
          <p className="logos-heading">RadioPublic is used by:</p>
          <div className="logos">
            {this._renderLogo(logoUSAT)}
            {this._renderLogo(logoET)}
            {this._renderLogo(logoVice)}
            {this._renderLogo(logoNYT)}
            {this._renderLogo(logoWGBH)}
            {this._renderLogo(logoWaPo)}
            {this._renderLogo(logoPRX)}
          </div>
          <p className="logos-heading">And found in abundance on the following sites:</p>
          <div className="logos">
            {this._renderLogo(logoReddit)}
            {this._renderLogo(logoMedium)}
            {this._renderLogo(logoWordPress)}
          </div>
        </section>
        <section className="segment-section-grid-squat">
          <div>
            <h4 className="grid-squat-heading">Smarter listener acquisition funnels</h4>
            <p className="section-subheading grid-squat-body">
              RadioPublic provides a necessary layer between your podcast and listeners: making smarter, faster, and easier connections between the
              audio you’re publishing and the audiences you’re reaching.
            </p>
            {this._renderGridSegment(
              'spot-1',
              emojiUpdate,
              'An auto-updating productivity booster',
              'Our embeds, podcast landing pages, and Podsites are efficient: they update whenever a new episode is published. No need to login and duplicate your work: publish once, and it’s everywhere.'
            )}
            {this._renderGridSegment(
              'spot-2',
              emojiGraph1,
              'Watch your listens soar with better links',
              'RadioPublic makes it easy to market your latest episode to your fans, helping them quickly stream on the platforms where they love to listen—selected by you.'
            )}
            {this._renderGridSegment(
              'spot-3',
              emojiPlay,
              'Control your player experience',
              'Play your podcasts without losing your visitors to distracting advertisements or suggested content—your embedded episodes are you, all you.'
            )}
            {this._renderGridSegment(
              'spot-4',
              emojiCursor,
              'Actionable calls to action',
              'Turn those listeners into subscribers—to your podcast and to your email newsletter, all from one action-packed embed player and podcast website.'
            )}
            {this._renderGridSegment(
              'spot-5',
              emojiGraph2,
              'Measurement with analytics',
              'Track valuable listening stats and measure what matters most with a Google Analytics plugin to better understand your audience. '
            )}
          </div>
        </section>
        <section className="testimonial-section">
          <p>
            “Within days of switching my Google Search campaign to point to my RadioPublic Podsite [for Civilized], my qual[ity] scores jumped by 3 to
            4 digits. For those who do search advertising, this is a big deal. It means more clicks at a lower cost.”
          </p>
          <div className="testimonial-image">
            <img className="sean" src={seanImage} alt="Sean Howard" />
          </div>
          <h2>Sean Howard</h2>
          <h3>Fable and Folly Productions</h3>
        </section>
        <section className="segment-section-grid-squat">
          <div>
            <h4 className="grid-squat-heading">
              <img src={emojiLightning} />
              Vrrooommm! Speed matters for your podcast
            </h4>
            <p className="section-subheading grid-squat-body">
              We’ll get right to the point: the speed with which your podcast loads affects your discoverability. You don’t want new listeners to
              bounce from your page, or for Google to dock your podcast landing pages for slowness. No cheetah-ing: with RadioPublic, you have fast
              loading times and higher audience retention, and that means more time listeners spend with your podcast.
            </p>
            {this._renderGridSegment(
              'spot-1',
              emojiMagnifier,
              'Search engine optimization',
              'Look great in Google search results with JSON-LD annotation to structure your podcast’s data. '
            )}
            {this._renderGridSegment(
              'spot-2',
              emojiConfetti,
              'Social optimized landing pages',
              'With Open Graph meta tags, you can control what images shows up when someone (like you!) links to your podcast on Facebook and Twitter.'
            )}
            {this._renderGridSegment(
              'spot-3',
              emojiBrain,
              'Intelligent caching and instant episode updates',
              'We use worldwide and local caching servers to ensure lightning fast delivery of your marketing assets. Our intelligent caching systems detect when a new episode of your show is available so you don’t have to. '
            )}
            {this._renderGridSegment(
              'spot-4',
              emojiMuscle,
              'Support for progressive downloads',
              'Faster than someone can say, “Are there more episodes of this podcast?” When listeners tap play on RadioPublic, the audio will start even before the download is complete.'
            )}
            {this._renderGridSegment(
              'spot-5',
              emojiTarget,
              'Reliable hosting',
              'Web tools you can rely on: RadioPublic is built using Amazon AWS, the same technology that powers Netflix, Sony, HBO, and Spotify.'
            )}
          </div>
        </section>
        <section className="segment-section-grid-tall purple">
          <div>
            <h4 className="grid-tall-heading">Podcast sharing made simple</h4>
            <p className="section-subheading grid-tall-body">
              We make it easy to (realistically) share your podcast everywhere.
              <br />
              <br />
              Adding podcasts to your website is super simple with RadioPublic’s lightweight embed codes. We keep the code snippets small, so they’re
              easy to embed and load ridiculously fast.
            </p>
            {this._renderGridSegment(
              'spot-1',
              emojiGlobe,
              'Embed everywhere you already post',
              'You want your episode to be where millions of people are, so we worked with WordPress, Medium, and Reddit to ensure your RadioPublic embed works effortlessly on these platforms.'
            )}
            {this._renderGridSegment(
              'spot-2',
              emojiShare,
              'Built-in social sharing ',
              'RadioPublic helps fans find and engage with your podcast’s brand across all your digital presences include Facebook, Twitter, Instagram, Twitch, and more.'
            )}
            {this._renderGridSegment(
              'spot-3',
              emojiLinks,
              'Hearmarks™ & timestamp links',
              'Send someone to exactly the right moment to start listening with podcast bookmarks.'
            )}
            <div className="grid-tall-side-align-title share-sample">
              <div>
                <img src={shareSampleImage} />
              </div>
              <div>
                <p>Send your podcast via SMS</p>
                <p>
                  Curb the “but I don’t know how to listen to a podcast” anxiety with text and email sharing. Go from listening on a computer to
                  listening on a phone in a matter of seconds.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-section">
          <p>
            “In the lead up to the launch of Michael Lewis's Against the Rules, RadioPublic anticipated our needs as a premium publisher, adapting
            their technology to help us create a highly functional site for the show with incredible speed and agility. The support was above and
            beyond all my expectations.”
          </p>
          <div className="testimonial-image">
            <img className="pushkin" src={pushkinImage} alt="RadioPublic seal" />
          </div>
          <h2>Heather Fain</h2>
          <h3>CMO, Pushkin Industries</h3>
        </section>
        <CallToActionSectionMultiline
          title="Sign up for free"
          textLines={[
            'Customizable podcast embed player',
            'Convert web visitors to subscribers',
            'Simple podcast sharing',
            'Calls To Action listener engagement tools',
            'Meaningful advanced listening analytics',
            'Unlimited user and listener access'
          ]}
          links={[{ text: 'Try for free', path: '/signup' }, { text: 'Compare plans', path: '/pricing' }]}
        />
      </Page>
    );
  }

  _renderGridSegment = (gridClassName: string, icon: any, title: string, text: string) => {
    return (
      <div className={`segment grid ${gridClassName}`}>
        {icon && <img src={icon} />}
        {title && <h6>{title}</h6>}
        <p>{text}</p>
      </div>
    );
  };

  _renderGridSegmentSmall = (title: string, text: string) => {
    return (
      <div className={`segment grid small`}>
        {title && <h6>{title}</h6>}
        <p>{text}</p>
      </div>
    );
  };

  _renderGridImage = (gridClassName: string, imageResource: any) => {
    return (
      <div className={`segment-image grid ${gridClassName}`}>
        <img src={imageResource} />
      </div>
    );
  };

  _renderSegment = (icon: any, title: string, text: string) => {
    return (
      <div className="segment">
        {icon && <img src={icon} />}
        {title && <h6>{title}</h6>}
        <p>{text}</p>
      </div>
    );
  };

  _renderLogo = (imageResource: any) => {
    return (
      <div className="logo">
        <img src={imageResource} />
      </div>
    );
  };

  _parseQueryStringParams = () => {
    // Set the prepopultedFeedUrl after the component is mounted in order for this to work with react-snapshot.
    // qs is used instead of whatwg-url, because the latter doesn't play nicely with create-react-app's tooling (https://github.com/jsdom/whatwg-url/issues/105)
    const queryString = this.props.location.search[0] === '?' ? this.props.location.search.slice(1, this.props.location.search.length) : '';
    const queryParams = qs.parse(queryString);
    // Other query params that are currently unused: feedArtist, feedTitle, feedImageUrl
    const { feedUrl } = queryParams;
    if (feedUrl) {
      SignupPage.setFeedUrl(feedUrl);
      // Remove the query params from the URL so the user doesn't see them.
      this.props.history.replace(this.props.history.location.pathname, this.props.history.location.state);
      localStorage.setItem(STORAGE_KEY_INCOMING_FEED_URL, feedUrl);
      this.setState({ initialFeedUrl: feedUrl });
      return;
    }

    //check storage as backup for navigating away/back
    const storedFeedUrl = localStorage.getItem(STORAGE_KEY_INCOMING_FEED_URL);
    if (storedFeedUrl) {
      this.setState({ initialFeedUrl: storedFeedUrl });
    } else {
      this.setState({ initialFeedUrl: DEFAULT_EMBED_PLAYER_FEED });
    }
  };
}
