import React, { Component } from 'react';
import dailyEmbedImage from './assets/daily-embed.png';
import signalEmbedImage from './assets/signal-embed.png';
import './styles.scss';

export default class EmbedImages extends Component {
  render() {
    return (
      <div className="embed-images">
        <img src={dailyEmbedImage} alt="The Daily podcast in the RadioPublic embed player." />
        <img src={signalEmbedImage} alt="The Signal podcast in the RadioPublic embed player." />
      </div>
    );
  }
}
