import pick from 'lodash/pick';
import config from '../config';
import assertOk from '../utils/assertOk';
import PodcasterAuthorizer from './PodcasterAuthorizer';

export enum SignupErrorType {
  INVALID_FEED = 'INVALID_FEED',
  NO_EMAIL_ADDRESSES = 'NO_EMAIL_ADDRESSES',
  RECORD_ALREADY_EXISTS = 'RECORD_ALREADY_EXISTS'
}

class SignupError extends Error {
  constructor(message, public type: SignupErrorType) {
    super(message);
  }
}

export default class PodcasterService {
  static async contactUs(options: { subject: string; message: string; email: string }) {
    await PodcasterAuthorizer.fetchAndAssertOk(`${this._baseUrl}/v1/podcasters/feeds/${PodcasterAuthorizer.programId}/contact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subject: options.subject,
        message: options.message,
        email: options.email
      })
    });
  }

  static async getCommunicationPreferences(): Promise<{ podcasterMailingList: boolean }> {
    const response = await PodcasterAuthorizer.fetchAndAssertOk(
      `${this._baseUrl}/v1/podcasters/feeds/${PodcasterAuthorizer.programId}/communication-preferences`
    );
    return response.json();
  }

  static async getEmailAddresses(options: { feedUrl: string }): Promise<string[]> {
    const response = await fetch(`${this._baseUrl}/v1/podcasters/emails?url=${encodeURIComponent(options.feedUrl)}`);
    if (!response.ok) {
      const errorBody = await response.json();
      throw new SignupError('API returned status code: ' + response.status, errorBody.type);
    }
    const addressObjects = await response.json();
    return addressObjects.map(a => a.address);
  }

  static async completeFeedVerification(options: {
    feedUrl: string;
    ownershipVerificationId: string;
    email: string;
    optedIntoMailingList: boolean;
    optedIntoPaidListens: boolean;
  }): Promise<{ programId: string }> {
    const requiredParams = ['feedUrl', 'ownershipVerificationId', 'email', 'optedIntoMailingList', 'optedIntoPaidListens'];
    const response = await fetch(`${this._baseUrl}/v1/podcasters/feeds/verification/complete`, {
      method: 'POST',
      body: JSON.stringify(pick(options, requiredParams))
    });
    await assertOk(response);
    const json = await response.json();
    if (json.authenticationToken) {
      PodcasterAuthorizer.setToken(json.authenticationToken);
    }
    return json;
  }

  static async getFeedVerificationStatus(options: { ownershipVerificationId: string }): Promise<{ isPendingVerification: boolean }> {
    const response = await fetch(`${this._baseUrl}/v1/podcasters/feeds/verification/${options.ownershipVerificationId}`);
    await assertOk(response);
    return response.json();
  }

  static async initiateFeedVerification(options: { feedUrl: string; paidListens: boolean; pendingPromoCode?: string }) {
    const response = await fetch(`${this._baseUrl}/v1/podcasters/feeds/verification`, {
      method: 'POST',
      body: JSON.stringify({
        feedUrl: options.feedUrl,
        paidListens: options.paidListens,
        pendingPromoCode: options.pendingPromoCode
      })
    });
    await assertOk(response);
  }

  /**
   * Returns true if the email was sent, returns false if the email matched no paid-listens feeds
   */
  static async initiateLogin(options: { email: string; location: string }): Promise<boolean> {
    const { email, location } = options;
    const body = location ? JSON.stringify({ email, location }) : JSON.stringify({ email });
    const response = await fetch(`${this._baseUrl}/v1/podcasters/login/email`, {
      method: 'POST',
      body: body
    });
    if (response.ok) {
      return true;
    }
    if (response.status === 400) {
      return false;
    }
    throw new Error('API returned status code: ' + response.status);
  }

  static async getFeedStatus() {
    const response = await PodcasterAuthorizer.fetchAndAssertOk(`${this._baseUrl}/v1/paid-listens/feeds/${PodcasterAuthorizer.programId}`);
    return response.json();
  }

  static async setEmailOptOut(options: { programId: string; emailType?: string }) {
    const emailType = options.emailType || 'verification-reminder';
    const response = await fetch(`${this._baseUrl}/v1/podcasters/feeds/${options.programId}/email-opt-out`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        emailType: emailType
      })
    });
    await assertOk(response);
  }

  static async updateCommunicationPreferences(options: { preferences: { podcasterMailingList: boolean } }) {
    await PodcasterAuthorizer.fetchAndAssertOk(`${this._baseUrl}/v1/podcasters/feeds/${PodcasterAuthorizer.programId}/communication-preferences`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options.preferences)
    });
  }

  static async validateFeed(options: { programId: string }) {
    const response = await fetch(`${this._baseUrl}/v1/paid-listens/validator?programId=${options.programId}`);
    await assertOk(response);
    return response.json();
  }

  private static get _baseUrl() {
    return config.RADIOPUBLIC_API_BASE_URL;
  }
}
