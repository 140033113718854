import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rpInitialsFlagImage from '../../../shared-assets/rp-initials-flag-red.svg';
import AdaptableLink from '../../AdaptableLink';
import './styles.scss';
import chevronSvg from '../../../containers/PodsiteDashboardPage/SidebarNav/assets/chevron-down.svg';
import { SidebarLinkSection } from '../../../containers/PodsiteDashboardPage/SidebarNav';
import { Tab } from '../../../containers/PodsiteDashboardPage';
import { isSectionValid, isTabValid } from '../../../containers/PodsiteDashboardPage/errorChecker';

interface MobileMenuProps {
  menuSections: SidebarLinkSection[];
  onDismiss: () => void;
  publishError?: any;
}

export default class MobileMenu extends Component<MobileMenuProps> {
  state = {
    expandedSection: null as string | null
  };

  constructor(props) {
    super(props);
    if (this.props.menuSections.length == 1) {
      this.state = {
        expandedSection: this.props.menuSections[0].title
      };
    }
  }

  render() {
    const expandedSection = this.props.menuSections.find(section => {
      return section.title === this.state.expandedSection;
    });
    return (
      <nav className="mobile-menu">
        <div>
          <Link to="/" onClick={this.props.onDismiss}>
            <img className="rp-logo" src={rpInitialsFlagImage} alt="RadioPublic logo" />
          </Link>
          <button onClick={this.props.onDismiss}>✕</button>
        </div>
        {expandedSection ? <ul>{this._renderSection(expandedSection)}</ul> : <ul>{this.props.menuSections.map(this._renderSection)}</ul>}
      </nav>
    );
  }

  private _renderLink = (tab: Tab) => {
    const isValid = this.props.publishError ? isTabValid(this.props.publishError, tab) : true;
    const className = !isValid ? 'tab-error' : '';
    return (
      <li className={className} key={tab.title}>
        <AdaptableLink to={tab.url} onClick={this.props.onDismiss}>
          {tab.title}
        </AdaptableLink>
      </li>
    );
  };

  private _renderSection = (linkSection: SidebarLinkSection) => {
    const isValid = this.props.publishError ? isSectionValid(this.props.publishError, linkSection) : true;
    const renderAsLink = linkSection.isTopLevelLink === true && linkSection.tabs.length == 1;
    return renderAsLink ? this._renderSectionAsLink(linkSection, isValid) : this._renderSectionAsCollapsable(linkSection, isValid);
  };

  private _renderSectionAsLink = (linkSection: SidebarLinkSection, isValid: boolean) => {
    const tab = linkSection.tabs[0];
    return (
      <li>
        <div className={`sidebar-nav-section-heading ${isValid ? '' : ' section-error'}`} onClick={this.props.onDismiss}>
          <AdaptableLink to={tab.url}>{tab.title}</AdaptableLink>
        </div>
      </li>
    );
  };

  private _renderSectionAsCollapsable = (linkSection: SidebarLinkSection, isValid: boolean) => {
    const expanded = this.state.expandedSection === linkSection.title;
    return (
      <li>
        <div className={`sidebar-nav-section-heading ${isValid ? '' : ' section-error'}`} onClick={() => this._handleSectionToggle(linkSection)}>
          <AdaptableLink to="#">{linkSection.title}</AdaptableLink>
          <img className={`sidebar-nav-section-arrow ${expanded ? 'expanded' : ''}`} src={chevronSvg} />
        </div>
        {<ul className={`${expanded ? '' : 'collapsed'}`}>{linkSection.tabs.map(this._renderLink)}</ul>}
      </li>
    );
  };

  private _handleSectionToggle = (section: SidebarLinkSection) => {
    this.toggleSectionExpansion(section.title);
  };

  private toggleSectionExpansion(sectionTitle: string, forceEnable?: boolean) {
    const toggled = this.state.expandedSection === sectionTitle;
    this.setState({
      expandedSection: toggled ? null : sectionTitle
    });
  }
}
