import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import arrowImage from './assets/blue-arrow.svg';
import AdaptableLink from '../../components/AdaptableLink';
import './styles.scss';

interface LinksNavProps extends RouteComponentProps {
  links: Array<{ href: string; text: string; isNew?: boolean }>;
  className?: string;
  style?: any;
}

class LinksNav extends Component<LinksNavProps> {
  render() {
    const { links } = this.props;
    const currentPageIndex = findIndex(links, link => link.href === this.props.location.pathname);
    const currentPageIsNotInLinks = currentPageIndex === -1;
    const previousPageLink = links[currentPageIndex - 1] || links[links.length - 1];
    const nextPageLink = links[currentPageIndex + 1] || links[0];

    return (
      <div className="links-nav">
        <nav className={currentPageIsNotInLinks ? 'none-active' : ''}>{links.map(this._renderLink)}</nav>
        <div className={this.props.className} style={this.props.style}>
          {this.props.children}
        </div>
        <nav style={currentPageIsNotInLinks ? { display: 'none' } : undefined}>
          <div>
            <AdaptableLink to={previousPageLink.href}>
              <img src={arrowImage} alt="" />
              {previousPageLink.text}
            </AdaptableLink>
            <div>
              <span>Keep exploring</span>
            </div>
            <AdaptableLink to={nextPageLink.href}>
              {nextPageLink.text}
              <img src={arrowImage} alt="" />
            </AdaptableLink>
          </div>
        </nav>
      </div>
    );
  }

  _renderLink = link => {
    const activeLink = this.props.links.find(l => l.href === this.props.location.pathname);
    const className = activeLink === link ? 'active' : !activeLink ? 'dark' : '';
    return (
      <div className="links-nav_link">
        <AdaptableLink key={link.text} className={className} to={link.href}>
          {link.text}
        </AdaptableLink>
        {!!link.isNew && <p className="links-nav_link-new">new!</p>}
      </div>
    );
  };
}

export default withRouter(LinksNav);
