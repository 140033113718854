import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import PageHeaderV2, { LinkData } from '../PageHeaderV2';
import PageFooter from '../PageFooter';
import TopBanner from './TopBanner';
import './styles.scss';
import { SidebarLinkSection } from '../../containers/PodsiteDashboardPage/SidebarNav';

enum BannerState {
  DEFAULT,
  SHOWN,
  HIDDEN,
  TRANSITIONING
}

interface PageProps {
  className?: string;
  headerClassName?: string;
  simplifiedHeader?: boolean;
  topBanner?: { analyticsLabel: string; title: string; buttonText: string; buttonDestination: string };
  mobileLinks?: SidebarLinkSection[];
  title?: string;
  publishError?: any;
  //differentiate header nav from dashboard (links content controlled by page, no sections, no error support)
  useV2Header?: boolean;
  v2DesktopNavLinks?: LinkData[]; //optional links to override defaults
  v2MobileNavLinks?: LinkData[];
}

export default class Page extends Component<PageProps> {
  state = { bannerState: BannerState.DEFAULT };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._triggerBannerIfNeeded();
  }

  componentDidUpdate(previousProps) {
    this._triggerBannerIfNeeded(previousProps);
  }

  render() {
    const { bannerState } = this.state;
    const includeTopBannerInDom = bannerState === BannerState.SHOWN || bannerState === BannerState.TRANSITIONING;
    // position:relative needs to be inline, because if it's a class, react-snapshot strips it out for some reason.
    return (
      <div className={`page-component ${bannerState === BannerState.SHOWN ? 'with-top-banner' : ''}`} style={{ position: 'relative' }}>
        <Helmet>
          <title>{this.props.title || 'RadioPublic Podcasters'}</title>
        </Helmet>
        {includeTopBannerInDom && <TopBanner {...this.props.topBanner} onDismiss={this._handleTopBannerDismissal} />}
        <div className={`header-container ${this.props.headerClassName || ''}`}>
          {this.props.useV2Header === true ? (
            <PageHeaderV2
              simplifiedHeader={this.props.simplifiedHeader}
              desktopNavLinks={this.props.v2DesktopNavLinks}
              mobileNavLinks={this.props.v2MobileNavLinks}
            />
          ) : (
            <PageHeader simplifiedHeader={this.props.simplifiedHeader} menuSections={this.props.mobileLinks} publishError={this.props.publishError} />
          )}
        </div>
        <div className={this.props.className || ''}>{this.props.children}</div>
        <PageFooter />
      </div>
    );
  }

  _handleTopBannerDismissal = () => {
    this.setState({ bannerState: BannerState.TRANSITIONING });
    setTimeout(() => this.setState({ bannerState: BannerState.HIDDEN }), 200);
  };

  _triggerBannerIfNeeded(previousProps?: PageProps) {
    if (this.props.topBanner && !(previousProps && previousProps.topBanner)) {
      setTimeout(() => this.setState({ bannerState: BannerState.SHOWN }), 1500);
    }
  }
}
