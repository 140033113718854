import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Analytics from 'react-ga';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import backgroundArms from './assets/bg-arms.jpg';
import rpSealImage from './assets/rp-seal.svg';
import checkBlue from './assets/check-blue.svg';
import './styles.scss';

export default class HomePage extends Component<RouteComponentProps> {
  componentDidMount() {
    Analytics.pageview('/pricing');
  }

  render() {
    return (
      <Page className="pricing-page" title="Pricing for Podcast Marketing Tools on RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={backgroundArms} justifyBackground="center" aspectRatio="2/1">
          <h1>Plans</h1>
        </PanelWithBackdrop>
        <section className="plans-section">
          <div className="plans-content">
            <div className="plan-column spot-1">
              <h2>Basic</h2>
              <h5>$0 per month</h5>
              <h6> </h6>
              <div className="feature-group">
                {this.renderFeature(checkBlue, 'Embed episodes anywhere', [
                  'Flexible, responsive design',
                  'Enhanced support for Medium, WordPress, Reddit',
                  'Includes RadioPublic branding'
                ])}
                {this.renderFeature(checkBlue, 'Standard podcast marketing landing page', [
                  'Highlight featured episodes',
                  'Showcase host/contributor bios',
                  'Share listener testimonials',
                  'Add listening platforms, website, & social links'
                ])}
                {this.renderFeature(checkBlue, 'Listener discovery and sharing', [
                  'Inclusion in RadioPublic search catalog',
                  'Universal native app links',
                  'Social media sharing'
                ])}
                {this.renderFeature(checkBlue, 'New episodes automatically appear', [
                  'Default listening stats',
                  'Support via help articles',
                  'RadioPublic branding'
                ])}
                <Link className="button-white" to="/signup">
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="rp-section">
          <img src={rpSealImage} alt="RadioPublic seal" />
          <h2>We are RadioPublic</h2>
          <p>We help podcasters grow their businesses and listeners discover them and we’ve been doing it for 15 years.</p>
          <a className="link-with-arrow" href="https://about.radiopublic.com/">
            Learn more about RadioPublic
          </a>
        </section>
      </Page>
    );
  }

  renderFeature = (checkImage: any, title: string, details: string[]) => {
    return (
      <div className="feature">
        <h3>
          <img src={checkImage} /> {title}
        </h3>
        {details.map((line, index) => {
          return <p key={index}>{line}</p>;
        })}
      </div>
    );
  };
}
