import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import ToolsNav from '../../components/ToolsNav';
import keyboardBackgroundImage from './assets/keyboard-background.jpg';
import mindMapImage from './assets/mind-map.svg';
import mobileMarketingImage from './assets/mobile-marketing.svg';
import mobilePaymentsImage from './assets/mobile-payments.svg';
import postOnlineImage from './assets/post-online.svg';
import './styles.scss';

export default class MarketingPage extends Component {
  componentDidMount() {
    Analytics.pageview('/marketing');
  }

  render() {
    return (
      <Page className="marketing-page" title="Grow your audience with a free podcast marketing course from RadioPublic" useV2Header={true}>
        <PanelWithBackdrop
          className="top-panel"
          backgroundImage={keyboardBackgroundImage}
          arrowLinkHref="#intro"
          aspectRatio="2/1"
          justifyBackground="left"
        >
          <h1>Podcast marketing made simple</h1>
          <p>Create a FREE account on RadioPublic to receive our 21 day podcast marketing course by email</p>
          <Link className="jumbo-round-link" to="/signup">
            Get started
          </Link>
        </PanelWithBackdrop>
        <ToolsNav className="sections">
          <h1>Here's what you'll learn</h1>
          <section id="intro">
            <div>
              <img src={mindMapImage} alt="" />
            </div>
            <div>
              <h2>Make podcast listening a piece of cake</h2>
              <p>Linking to, embedding, and sharing your podcast help casual visitors become listeners to your show.</p>
            </div>
          </section>
          <section className="reversed">
            <div>
              <h2>Plan your social media and email marketing</h2>
              <p>Scheduling templates and step-by-step planning for one-on-one and one-to-many marketing tactics.</p>
            </div>
            <div>
              <img src={mobileMarketingImage} alt="" />
            </div>
          </section>
          <section>
            <div>
              <img src={mobilePaymentsImage} alt="" />
            </div>
            <div>
              <h2>Prep that press kit</h2>
              <p>Create your podcast press kit and distribute it to our ever-growing press list of people who write about podcasts.</p>
            </div>
          </section>
          <section className="reversed">
            <div>
              <h2>Develop and engage your audience</h2>
              <p>
                Build your listener base with tried and true podcast ads and cross-promotion and involve your community with live events and social
                campaigns.
              </p>
            </div>
            <div>
              <img src={postOnlineImage} alt="" />
            </div>
          </section>
        </ToolsNav>
      </Page>
    );
  }
}
