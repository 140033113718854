import React, { Component } from 'react';
import Analytics from 'react-ga';
import LearningNav from '../../components/LearningNav';
import LinkList from '../../components/LinkList';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import laptopBackgroundImage from './assets/laptop-background.jpg';

export default class ImprovePage extends Component {
  componentDidMount() {
    Analytics.pageview('/improve');
  }

  render() {
    return (
      <Page className="learning-page-base" title="Improve and level up your podcast from your friends at RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={laptopBackgroundImage} arrowLinkHref="#intro" aspectRatio="2/1">
          <h1>Improving your podcast</h1>
          <p>Ready take your podcast to the next level?</p>
        </PanelWithBackdrop>
        <LearningNav className="sections">
          <article>
            <p>
              It's time to take your podcast to the next level. Lucky for you, there are tons of resources out there that break down how to do just
              that. Whether you’re looking to keep your content fresh, book great interviews, or explore sound design, you’re well-equipped to take
              your show from <i>meh</i> to marvelous with these helpful tools and advice.
            </p>
            <p>
              Who says you have to be an audio-editing wizard to produce a good podcast? If you’re looking to enhance your audience’s listening
              experience, these tips from the pros can elevate your sound, whether you’re making a sound-rich audio drama or an informal roundtable
              chat-cast.
            </p>
            <h2>Make your podcast sound good</h2>
            <p>
              Part of podcast improvement is realizing if and when parts of your process are better done by someone other than yourself. While it
              costs money to hire people to work on different parts of your podcasts, distributing to talented editors, sound designers, and voice
              over artists allows you to focus on the pieces of your podcast you’re most excited to work on. As you spend more time making your show
              and reading about how to improve your show, use this a moment to identify opportunities to work with others–and jumpstart it with advice
              and resources for finding and hiring additional help.
            </p>
            <h3 id="intro">Structure and storytelling</h3>
            <LinkList
              links={[
                {
                  text: 'Good Tape',
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-5/',
                  description: '(HowToMakeAPodcast.org)'
                },
                {
                  text: "It's All In Your Head: The One-Way Intimacy Of Podcast Listening",
                  href: 'https://www.npr.org/2018/02/02/582105045/its-all-in-your-head-the-one-way-intimacy-of-podcast-listening',
                  description: '(NPR)'
                },
                {
                  text: 'Learning by Listening',
                  href: 'https://bellocollective.com/learning-by-listening-9376a2e17aa9',
                  description: '(Bello Collective)'
                },
                {
                  text: 'Anatomy of a Scene',
                  href: 'https://transom.org/2018/anatomy-of-a-scene/',
                  description: '(Howsound)'
                },
                {
                  text: 'Writing for Audio, Not Articles: Subtle Differences Make All the Difference',
                  href: 'https://www.marketingshowrunners.com/blog/writing-for-audio-text-articles-differences/',
                  description: '(Marketing Showrunners)'
                }
              ]}
            />
            <h3>Improve your audio production</h3>
            <LinkList
              links={[
                {
                  text: "5 Ways to Improve Your Podcast Listener's Experience",
                  href: 'https://www.thepodcasthost.com/editing-production/improving-podcast-listener-experience/',
                  description: '(The Podcast Host)'
                },
                {
                  text: 'Audio production FAQ: Headphones, levels, mics and more',
                  href: 'https://training.npr.org/audio/audio-production-faq-headphones-levels-mics/',
                  description: '(NPR)'
                },
                {
                  text: 'Choosing the right equipment — Microphones, Mixers and other gear',
                  href: 'https://medium.com/spext/how-to-start-a-podcast-choosing-the-right-equipment-microphones-mixers-and-other-gear-73441a9366bf',
                  description: '(Spext)'
                },
                {
                  text: 'Sound as a Place',
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-6/',
                  description: '(HowToMakeAPodcast.org)'
                },
                {
                  text: 'How Do I Make My Podcast Sound Better?',
                  href: 'https://www.thepodcasthost.com/equipment/how-do-i-make-my-podcast-sound-better/',
                  description: '(The Podcast Host)'
                },
                {
                  text: 'Setting Up a Small Recording Studio',
                  href: 'https://transom.org/2012/small-recording-studio-update/',
                  description: '(Transom)'
                },
                {
                  text: 'Voice Recording in the Home Studio',
                  href: 'https://transom.org/2013/voice-recording-in-the-home-studio/',
                  description: '(Transom)'
                }
              ]}
            />
            <h3>Digital editing</h3>
            <LinkList
              links={[
                {
                  text: 'Sharing Sessions, Part 1: Organization',
                  description: '(Transom)',
                  href: 'https://transom.org/2017/sharing-sessions-part-1-organization/'
                },
                {
                  text: 'Podcasting Basics, Part 3: Audio Levels and Processing',
                  description: '(Transom)',
                  href: 'https://transom.org/2015/podcasting-basics-part-3-audio-levels-and-processing/'
                },
                {
                  text: 'Podcasting Basics, Part 5: Loudness for Podcasts vs. Radio',
                  description: '(Transom)',
                  href: 'https://transom.org/2016/podcasting-basics-part-5-loudness-podcasts-vs-radio/'
                },
                {
                  text: 'Preserve This Podcast Zine',
                  description: '(Preserve This Podcast)',
                  href: 'http://preservethispodcast.org/assets/PreserveThisPodcast_Zine_Online.pdf'
                }
              ]}
            />
            <h3>Voicing</h3>
            <LinkList
              links={[
                {
                  text: 'Aerobics for your voice: 3 tips for sounding better on air',
                  description: '(NPR)',
                  href: 'https://training.npr.org/audio/aerobics-for-your-voice-3-tips-for-sounding-better-on-air/'
                },
                {
                  text: 'Human vs. Robot: How to track and still sound like your actual self',
                  description: '(Third Coast)',
                  href: 'https://radiopublic.com/third-coast-pocket-conference-G2aYzz/ep/s1!6f2a2'
                },
                {
                  text: 'Sounding Like Yourself',
                  description: '(Transom)',
                  href: 'https://transom.org/2017/sounding-like-yourself/'
                },
                {
                  text: 'How to Sound Pretty Good on a Podcast: Useful Advice for Beginners',
                  description: '(Kickstarter)',
                  href: 'https://medium.com/kickstarter/how-to-sound-pretty-good-on-a-podcast-useful-advice-for-beginners-c40c64649227'
                }
              ]}
            />
            <h3>Sound design</h3>
            <LinkList
              links={[
                {
                  text: 'Sound Design: Mark Henry Phillips',
                  description: '(Transom)',
                  href: 'https://transom.org/2018/sound-design-mark-henry-phillips/'
                },
                {
                  text: 'The Sound of Adventure: A Guide to Soundscaping',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/the-sound-of-adventure-an-audio-guide-to-soundscaping-6c998db1f5e2'
                },
                {
                  text: 'Sound Design Basics',
                  description: '(Howsound)',
                  href: 'https://transom.org/2017/sound-design-basics/'
                }
              ]}
            />
            <h3>Hiring additional help</h3>
            <LinkList
              links={[
                {
                  text: 'How to Hire Freelancers for your Indie Project',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/building-your-podcast-team-115906e49fe7'
                },
                {
                  text: 'Talent Directory',
                  description: '(AIR)',
                  href: 'https://airmedia.org/talent-directory/'
                },
                {
                  text: 'Why It Pays to Have a Podcast Editing Service',
                  description: '(Podcast Motor)',
                  href: 'https://www.podcastmotor.com/why-it-pays-to-have-a-podcast-editing-service/'
                },
                {
                  text: 'The Podcast Production Directory',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/podcast-production-directory/'
                },
                {
                  text: '10 Things to Know Before Hiring a Podcast Editor',
                  description: '(The Podcast Dude)',
                  href: 'https://seanwes.com/podcastdude/008-10-things-to-know-before-hiring-a-podcast-editor/'
                },
                {
                  text: 'What does a podcast producer do?',
                  description: '(Podcast Engineers)',
                  href: 'http://podcastengineers.com/blogs/what-does-podcast-producer-do'
                },
                {
                  text: 'Podcast Production Companies',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2017/07/11/Podcast-production-companies/'
                }
              ]}
            />
            <h2>Make your podcast look good</h2>
            <p>
              Listeners are choosing what to listen to next with their eyes first. Make your supplemental web content beautiful, user-friendly, and
              helpful. In other words, make it easy for listeners to discover, explore, and love your show!
            </p>
            <LinkList
              links={[
                {
                  text: 'Audio Artwork',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/audio-artwork-471e9848146b'
                },
                {
                  text: 'Why Your Podcast Needs Show Notes',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/why-your-podcast-needs-show-notes/'
                },
                {
                  text: 'The Podcaster’s Guide to Transcribing Audio',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/the-podcasters-guide-to-transcribing-audio-2121f9e7992f'
                },
                {
                  text: 'Mind Your Metadata: A Podcaster’s Guide to Titles and Descriptions',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/mind-your-metadata-a-podcasters-guide-to-titles-and-descriptions-479087734e6e'
                },
                {
                  text: 'Using RadioPublic’s free embed player',
                  description: '(RadioPublic)',
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360000504994-Using-RadioPublic-s-free-embed-player'
                },
                {
                  text: 'How to Start Using the New iTunes Podcast Tags for iOS 11',
                  description: '(The Audacity to Podcast)',
                  href: 'https://theaudacitytopodcast.com/how-to-start-using-the-new-itunes-podcast-tags-for-ios-11-tap316/'
                },
                {
                  text: 'Podcast Merch: A Fun Way To Build Community & Promote Your Show',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/promotion/podcast-merch/'
                },
                {
                  text: 'How to make an article on your Podsite',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2019/03/19/how-to-make-an-article-on-your-podsite/'
                }
              ]}
            />
            <h2>Make the most of your interviews</h2>
            <p>
              Calling all interview-based podcasters! Whether you’re aiming to book great guests for your show or want to polish your interviewing
              skills, the following guides are here to help. Learn how to find, book, and interview guests that will inject a new energy into your
              show and provide your audience with compelling conversations and interesting insights.
            </p>
            <LinkList
              links={[
                {
                  text: '21 Great Resources for Finding Podcast Guests',
                  description: '(Podcast Websites)',
                  href: 'https://podcastwebsites.com/2016/08/20-finding-podcast-guest/'
                },
                {
                  text: 'How to Set Up a Guest Interview for Your Podcast',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/how-to-setup-a-guest-interview-for-your-podcast/'
                },
                {
                  text: 'Mastering the Grill: Why Some Interviews Go Up in Smoke',
                  description: '(Third Coast)',
                  href: 'https://www.thirdcoastfestival.org/explore/feature/mastering-the-grill-why-some-interviews-go-up-in-smoke'
                },
                {
                  text: 'The Art of the Podcast Interview: What I’ve Learned from 700 Episodes',
                  description: '(Srinivas Rao)',
                  href: 'https://medium.com/the-mission/the-art-of-the-podcast-interview-what-ive-learned-from-700-episodes-73ed3ed3f1ea'
                },
                {
                  text: 'Your guests are an opportunity for audience growth',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/09/05/your-guests-are-an-opportunity-for-audience-growth-part-1/'
                }
              ]}
            />
            <h2>Make content your listeners want to hear</h2>
            <p>
              It’s hard to know what your audience wants if you don’t ask them. And if you’re seeking more audience engagement, these tools will help
              you get there:
            </p>
            <LinkList
              links={[
                {
                  text: '7 Secrets To Producing Engaging Podcast Content',
                  description: '(Forbes)',
                  href: 'https://www.forbes.com/sites/jonlevy/2017/12/18/7-secrets-to-produce-engaging-podcast-content/#40720f7c1b6f'
                },
                {
                  text: 'How to Test Your Audience',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/planning/how-to-test-your-audience/'
                },
                {
                  text: 'What’s There and What’s Not: A Guide to Constructive Criticism for Podcasts',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/whats-there-and-what-s-not-a-guide-to-constructive-criticism-for-podcasts-abf2fc5a4674'
                },
                {
                  text: 'Building Your Podcast’s Community',
                  description: '(Amanda McLoughlin)',
                  href: 'https://medium.com/@shessomickey/building-your-podcasts-community-414e599c1409'
                },
                {
                  text: '7 Strategies for Building a Strong Podcast Community',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/7-strategies-for-building-a-strong-podcast-community/'
                },
                {
                  text: 'How do Lesser Known Podcasts Build an Audience?',
                  description: '(Ashley Milne-Tyte)',
                  href: 'http://www.ashleymilnetyte.com/?p=3461'
                },
                {
                  text: 'Introducing Affinity Promotions for podcasts',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2017/09/12/introducing-affinity-promotions-for-podcasts-on-radiopublic/'
                }
              ]}
            />
          </article>
        </LearningNav>
      </Page>
    );
  }
}
