export default class ITunesService {
  static async getFeedUrlFromITunesLink(itunesUrl: string): Promise<string> {
    try {
      const iTunesId = this.getIdFromUrl(itunesUrl);
      const response = await fetch(`http://itunes.apple.com/lookup?id=${encodeURIComponent(iTunesId)}`);
      if (response.ok) {
        const json = await response.json();
        if (json.resultCount > 0) {
          const feedUrl = json.results[0].feedUrl;
          return feedUrl;
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching autocomplete suggestions: ' + error.stack);
    }
    return '';
  }

  private static getIdFromUrl(itunesUrl: string): string {
    const url = new URL(itunesUrl);
    const segments = url.pathname.split('/');
    var id = '';
    var i = 0;
    while (segments && i < segments.length) {
      if (segments[i].startsWith('id')) {
        id = segments[i].replace('id', '');
        break;
      }
      i++;
    }
    return id;
  }
}
