import React, { Component } from 'react';
import FooterLinkSection from './LinkSection';
import config from '../../config';
import facebookIcon from '../../shared-assets/facebook.svg';
import wordpressIcon from '../../shared-assets/wordpress.svg';
import rpFlagImage from '../../shared-assets/radiopublic-flag-red.svg';
import twitterIcon from '../../shared-assets/twitter.svg';
import './styles.scss';

export default class PageFooter extends Component {
  render() {
    return (
      <div className="page-footer">
        <div className="page-footer-container">
          <div className="footer-left">
            <div className="rp-logo">
              <a href={config.RADIOPUBLIC_MAIN_SITE_URL}>
                <img src={rpFlagImage} alt="RadioPublic" />
              </a>
            </div>
            <div>
              RadioPublic
              <br />
              79 Walker St
              <br />
              New York, NY 10013
            </div>
            <div className="social-links">
              <div className="social-link">
                <a href={config.FACEBOOK_URL}>
                  <img src={facebookIcon} alt="Facebook" />
                </a>
              </div>
              <div className="social-link">
                <a href={config.TWITTER_URL}>
                  <img src={twitterIcon} alt="Twitter" />
                </a>
              </div>
              <div className="social-link">
                <a href={config.ABOUT_RADIOPUBLIC_URL}>
                  <img src={wordpressIcon} alt="Blog" style={{ height: '30px' }} />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-middle">
            <div className="divider" />
          </div>
          <FooterLinkSection
            sectionTitle="Learning"
            linkColumns={[
              [
                { text: 'Starting a podcast', path: '/start' },
                { text: 'Getting better', path: '/improve' },
                { text: 'Growing audience', path: '/grow' },
                { text: 'Making money', path: '/revenue' }
              ]
            ]}
          />
          <FooterLinkSection
            sectionTitle="Helpful Links"
            linkColumns={[
              [
                { text: 'More about RadioPublic', path: config.ABOUT_RADIOPUBLIC_URL },
                { text: 'Terms of Service', path: config.TERMS_OF_SERVICE_URL },
                { text: 'Privacy Policy', path: config.PRIVACY_POLICY_URL },
                { text: 'Help', path: config.HELP_URL }
              ]
            ]}
          />
        </div>
      </div>
    );
  }
}
