import React from 'react';
import Spinner from '../Spinner';
import './styles.scss';

const LoadingView = props => {
  return (
    <div className={`loading-view-component ${props.className || ''}`}>
      <h1>{props.message}</h1>
      <div className="loading-spinner">
        <Spinner thickness="8px" size="50px" />
      </div>
      {props.details && <div className="loading-details">{props.details}</div>}
    </div>
  );
};

export default LoadingView;
