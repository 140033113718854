import React, { Component } from 'react';
import Analytics, { OutboundLink } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import config from '../../config';
import ErrorView from '../../components/ErrorView';
import LoadingView from '../../components/LoadingView';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import Draper from '../../services/Draper';
import PodcasterService, { SignupErrorType } from '../../services/PodcasterService';
import SignupProgressIndicator from './SignupProgressIndicator';
import EmailVerificationView from './EmailVerificationView';
import EmailVerificationSentView from './EmailVerificationSentView';
import EmailVerificationSuccessView from './EmailVerificationSuccessView';
import MailingListSignupView from './MailingListSignupView';
import PodcastVerificationView from './PodcastVerificationView';
import PodcastVerificationSuccessView from './PodcastVerificationSuccessView';
import TermsOfServiceView from './TermsOfServiceView';
import { PODSITE_TRIAL_STORAGE_KEY } from '../../components/PodsiteTrialPromo';
import './styles.scss';
import './shared.scss';

enum SignupState {
  PODCAST_VERIFICATION,
  PODCAST_VERIFICATION_IN_PROGRESS,
  PODCAST_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_IN_PROGRESS,
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_SUCCESS,
  TERMS_OF_SERVICE,
  MAILING_LIST,
  SIGNUP_COMPLETION_IN_PROGRESS
}

export default class SignupPage extends Component<RouteComponentProps> {
  static _prepopulatedFeedUrl;
  state = this._initialState;

  get _initialState() {
    return {
      signupState: SignupState.PODCAST_VERIFICATION,
      email: '',
      emailAddresses: [],
      errorState: null as null | {
        message: string;
        title: string;
        details: string;
        reportedError: string;
        buttonText?: string;
        buttonClickHandler?: () => void;
      },
      feedUrl: '',
      ownershipVerificationId: '',
      enteredThroughPaidListensPage: false
    };
  }

  componentDidMount() {
    Analytics.pageview('/signup');
    // This must go in componentDidMount() (as opposed to the componentWillMount) because of react-snapshot.
    this._parseQueryStringParams();
  }

  render() {
    return (
      <Page useV2Header={true}>
        <PanelWithBackdrop>
          <div className="signup-page">
            <div className="signup-modal">
              <SignupProgressIndicator step={this._getStepNumber()} />
              <div className="signup-modal-view-container">{this._renderView()}</div>
            </div>
          </div>
        </PanelWithBackdrop>
      </Page>
    );
  }

  /**
   * Lets the feed URL be prepopulated by another part of the application.
   * This is stored as a static property so that the user can wander around
   * the site, and the feed URL will still be prepopulated when they eventually
   * go to the signup page.
   */
  static setFeedUrl(feedUrl) {
    SignupPage._prepopulatedFeedUrl = feedUrl;
  }

  _continueToNextState = () => {
    const index = this._orderedStates.indexOf(this.state.signupState);
    this.setState({ signupState: this._orderedStates[index + 1] });
  };

  _getStepNumber = () => {
    switch (this.state.signupState) {
      case SignupState.EMAIL_VERIFICATION:
      case SignupState.EMAIL_VERIFICATION_IN_PROGRESS:
      case SignupState.EMAIL_VERIFICATION_SENT:
      case SignupState.EMAIL_VERIFICATION_SUCCESS:
        return 2;
      case SignupState.TERMS_OF_SERVICE:
      case SignupState.MAILING_LIST:
      case SignupState.SIGNUP_COMPLETION_IN_PROGRESS:
        return 3;
      default:
        return 1;
    }
  };

  /**
   * It's possible for the user to follow the link from an old verifiation email after they've already
   * verified that they own a podcast. This method handles that scenario by redirecting the user to login.
   */
  async _goToLoginIfNoVerificationPending(ownershipVerificationId) {
    let isPendingVerification = true;
    try {
      ({ isPendingVerification } = await PodcasterService.getFeedVerificationStatus({ ownershipVerificationId }));
    } catch (error) {
      console.log('An error occurred while checking the verification status: ' + error.stack); // eslint-disable-line no-console
    }
    if (!isPendingVerification) {
      this.props.history.push('/login');
    }
  }

  private _submitSignupCompletion = async optedIntoMailingList => {
    this._continueToNextState();
    try {
      const { programId } = await PodcasterService.completeFeedVerification({
        feedUrl: this.state.feedUrl,
        ownershipVerificationId: this.state.ownershipVerificationId,
        email: this.state.email,
        optedIntoMailingList,
        optedIntoPaidListens: this.state.enteredThroughPaidListensPage
      });
      Analytics.event({ category: 'Signup', action: 'Complete Signup' });
      this.props.history.push(`/dashboard?programId=${programId}&firstLogin=1`);
    } catch (error) {
      const reportedError = `An error occurred while completing feed verification:\n${error.message}\n${error.stack}`;
      console.log(reportedError); // eslint-disable-line no-console
      this.setState({
        errorState: {
          message: null, // use the default message
          reportedError
        }
      });
    }
  };

  _handleUrlSubmission = async providedUrl => {
    this.setState({ signupState: SignupState.PODCAST_VERIFICATION_IN_PROGRESS });
    try {
      Analytics.event({
        category: 'Signup',
        action: 'Submit Podcast URL',
        label: providedUrl
      });

      const urlIncludesScheme = providedUrl.indexOf('http://') === 0 || providedUrl.indexOf('https://') === 0;
      if (!urlIncludesScheme) {
        providedUrl = `http://${providedUrl}`;
      }
      let feedUrl;
      try {
        feedUrl = await Draper.getCanonicalFeedUrl({ url: providedUrl });
      } catch (error) {
        console.log(`An error occurred while fetching the canonical feed URL: ${error.stack}`);
        feedUrl = providedUrl;
      }
      const emailAddresses = await PodcasterService.getEmailAddresses({ feedUrl });
      this.setState({ emailAddresses, feedUrl });

      Analytics.event({
        category: 'Signup',
        action: 'Valid podcast provided',
        label: providedUrl
      });

      this._continueToNextState();
    } catch (error) {
      let title, message, buttonText, buttonClickHandler;

      Analytics.event({
        category: 'Signup',
        action: 'Invalid podcast provided',
        label: providedUrl
      });

      switch (error.type) {
        case SignupErrorType.INVALID_FEED:
          title = 'Invalid feed';
          message = <span>The link works but it isn’t in the correct format. It’s probably a link to your website; we need your podcast feed.</span>;
          break;
        case SignupErrorType.NO_EMAIL_ADDRESSES:
          title = 'No email addresses found';
          message = this._emailNotFoundMessage(providedUrl);
          break;
        case SignupErrorType.RECORD_ALREADY_EXISTS:
          Analytics.event({
            category: 'Signup',
            action: 'Already Signed Up',
            label: providedUrl
          });

          title = 'Already signed up';
          message = 'There is an existing record for that feed. Please log in.';
          buttonText = 'Log in';
          buttonClickHandler = () => this.props.history.push('/dashboard');
          break;
        default:
          Analytics.event({
            category: 'Signup',
            action: 'Invalid podcast submitted',
            label: providedUrl
          });

          console.log(`Error: ${error.stack}`); // eslint-disable-line no-console
          message = (
            <span>
              Try putting your feed through a{' '}
              <a href={config.PODCAST_FEED_VALIDATOR_URL} target="_blank">
                podcast validator
              </a>{' '}
              and try again.
            </span>
          );
      }

      const reportedError = `An error occurred during signup:\n${title}\n${error.message}\n${error.stack}`;
      this.setState({ errorState: { message, title, reportedError, buttonText, buttonClickHandler } });
    }
  };

  get _orderedStates() {
    return [
      SignupState.PODCAST_VERIFICATION,
      SignupState.PODCAST_VERIFICATION_IN_PROGRESS,
      SignupState.PODCAST_VERIFICATION_SUCCESS,
      SignupState.EMAIL_VERIFICATION,
      SignupState.EMAIL_VERIFICATION_IN_PROGRESS,
      SignupState.EMAIL_VERIFICATION_SENT,
      SignupState.EMAIL_VERIFICATION_SUCCESS,
      SignupState.TERMS_OF_SERVICE,
      SignupState.MAILING_LIST,
      SignupState.SIGNUP_COMPLETION_IN_PROGRESS
    ];
  }

  private _emailNotFoundMessage(providedUrl: string) {
    const showAnchorError = providedUrl.includes('anchor.fm');
    if (showAnchorError) {
      return (
        <span>
          We were able to read your podcast feed, but couldn't locate your email address in it to confirm you own it.{' '}
          <OutboundLink
            to={config.ANCHOR_DASHBOARD_LOGIN_URL}
            eventLabel="Anchor dashboard: Adjusting your email address in your RSS feed"
            target="_blank"
          >
            Login to your Anchor dashboard.
          </OutboundLink>{' '}
          Then click Settings, then Distributions. Enable the "Display personal email address publicly in RSS feed" toggle so that you are able to
          verify ownership of your RSS feed and try again.
        </span>
      );
    }

    return (
      <span>
        We were able to read your podcast feed, but couldn't locate your email address in it to confirm you own it. Please see{' '}
        <OutboundLink
          to={config.PAID_LISTENS_EMAIL_MISSING_HELP_URL}
          eventLabel="Help article: Adjusting your email address in your RSS feed"
          target="_blank"
        >
          this article
        </OutboundLink>{' '}
        for steps to resolve this.
      </span>
    );
  }

  /**
   * When the user enteres the signup flow through the Paid Listens landing page, the paidListens query param is included.
   *
   * When the user clicks on the link from the verification email, the email, ownershipVerificationId, and feedUrl
   * query params are included to indicate that the process should start at the email verification success step.
   */
  _parseQueryStringParams() {
    const queryString = this.props.location.search.replace('?', '');
    const { email, ownershipVerificationId, feedUrl, paidListens } = qs.parse(queryString);

    if (email && ownershipVerificationId && feedUrl) {
      Analytics.event({
        category: 'Signup',
        action: 'Podcast verified',
        label: feedUrl
      });

      this.setState({
        email,
        ownershipVerificationId,
        feedUrl,
        signupState: SignupState.EMAIL_VERIFICATION_SUCCESS,
        enteredThroughPaidListensPage: !!paidListens
      });
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: ''
      });
      this._goToLoginIfNoVerificationPending(ownershipVerificationId);
    } else if (feedUrl) {
      this._handleUrlSubmission(feedUrl);
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: ''
      });
    } else if (paidListens) {
      this.setState({ enteredThroughPaidListensPage: true });
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: ''
      });
    }
  }

  _renderView = () => {
    if (this.state.errorState) {
      return (
        <ErrorView
          title={this.state.errorState.title}
          message={this.state.errorState.message}
          details={this.state.errorState.details}
          reportedError={this.state.errorState.reportedError}
          buttonText={this.state.errorState.buttonText || 'Try again'}
          onButtonClick={this.state.errorState.buttonClickHandler || this._startOver}
        />
      );
    }
    switch (this.state.signupState) {
      case SignupState.PODCAST_VERIFICATION:
        return <PodcastVerificationView prepopulatedUrl={SignupPage._prepopulatedFeedUrl} onSubmit={this._handleUrlSubmission} />;
      case SignupState.PODCAST_VERIFICATION_IN_PROGRESS:
        return <LoadingView message="Validating your podcast" />;
      case SignupState.PODCAST_VERIFICATION_SUCCESS:
        return <PodcastVerificationSuccessView onContinue={this._continueToNextState} />;
      case SignupState.EMAIL_VERIFICATION_IN_PROGRESS:
        return <LoadingView message="Sending verification email" />;
      case SignupState.EMAIL_VERIFICATION:
        return (
          <EmailVerificationView
            feedUrl={this.state.feedUrl}
            emailAddresses={this.state.emailAddresses}
            onContinue={this._sendVerificationEmails}
            onStartOver={this._startOver}
          />
        );
      case SignupState.EMAIL_VERIFICATION_SENT:
        return <EmailVerificationSentView emailAddresses={this.state.emailAddresses} />;
      case SignupState.EMAIL_VERIFICATION_SUCCESS:
        return <EmailVerificationSuccessView onContinue={this._continueToNextState} />;
      case SignupState.TERMS_OF_SERVICE:
        return <TermsOfServiceView onContinue={this._continueToNextState} optedIntoPaidListens={this.state.enteredThroughPaidListensPage} />;
      case SignupState.MAILING_LIST:
        return <MailingListSignupView onContinue={this._submitSignupCompletion} />;
      case SignupState.SIGNUP_COMPLETION_IN_PROGRESS:
        return <LoadingView message="Completing signup" />;
      default:
        return null;
    }
  };

  _sendVerificationEmails = async () => {
    try {
      Analytics.event({
        category: 'Signup',
        action: 'Send Verification Emails',
        label: this.state.feedUrl
      });

      this.setState({ signupState: SignupState.EMAIL_VERIFICATION_IN_PROGRESS });

      const promoValue =
        localStorage.getItem(PODSITE_TRIAL_STORAGE_KEY) != null ? (localStorage.getItem(PODSITE_TRIAL_STORAGE_KEY) as string) : undefined;

      await PodcasterService.initiateFeedVerification({
        feedUrl: this.state.feedUrl,
        paidListens: this.state.enteredThroughPaidListensPage,
        pendingPromoCode: promoValue
      });
      this._continueToNextState();
    } catch (error) {
      const reportedError = `An error occurred while sending verification emails:\n${error.message}\n${error.stack}`;
      this.setState({
        errorState: {
          message: 'An error occurred while sending the feed verification email.',
          reportedError
        }
      });
    }
  };

  _startOver = () => {
    Analytics.event({
      category: 'Signup',
      action: 'Start over',
      label: this.state.feedUrl
    });

    this.setState((previousState: any) => {
      // Don't reset set the enteredThroughPaidListensPage property!
      const state = this._initialState;
      state.enteredThroughPaidListensPage = previousState.enteredThroughPaidListensPage;
      return state;
    });
  };
}
