import React, { Component } from 'react';
import Analytics from 'react-ga';
import LearningNav from '../../components/LearningNav';
import LinkList from '../../components/LinkList';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import workBackgroundImage from './assets/work-background.jpg';

export default class RevenuePage extends Component {
  componentDidMount() {
    Analytics.pageview('/revenue');
  }

  render() {
    return (
      <Page className="learning-page-base" title="Making money with your podcast from your friends at RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={workBackgroundImage} arrowLinkHref="#intro" aspectRatio="2/1">
          <h1>Making money with your podcast</h1>
          <p>Breaking even and earning more money from your show</p>
        </PanelWithBackdrop>
        <LearningNav className="sections">
          <article>
            <p id="intro">
              You’re making your podcast, your audience is growing, and now it’s time to make it official: you’re a check-cashing podcaster who not
              only breaks even, but earns money for their time and creative work. It’s possible, and we know you can do it. Let’s go!
            </p>
            <p>Before you start earning money, here are some ways to start wrapping your mind around podcast financing.</p>
            <LinkList
              links={[
                {
                  text: 'Podcast Accounting',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/accounting-for-podcasters-792bb1f1a667'
                },
                {
                  text: 'Google Podcast creator program Budget Webinar',
                  description: '(Google Podcast creator program and PRX)',
                  href: 'https://vimeo.com/303602096'
                },
                {
                  text: 'How Much Does It Really Cost to Produce Your Own Podcast?',
                  description: '(Podcast Engineers)',
                  href: 'http://podcastengineers.com/blogs/how-much-does-it-really-cost-produce-your-own-podcast'
                }
              ]}
            />
            <p>
              Podcast earnings come in a few forms, and break down into one of two different means: money earned from outside sources and money earned
              directly from listeners.
            </p>
            <h2>Outside support</h2>
            <p>
              The primary form of outside financial support is through sponsorships and ads. Whether you’re on a CPM (cost per mille, or one
              thousand), CPA (cost per acquisition), or flat rate agreement, these deals are based on the overall audience reached by a sponsor’s
              product - the ad itself.
            </p>
            <h3>The basics</h3>
            <LinkList
              links={[
                {
                  text: 'Podcast advertising glossary',
                  description: '(Advertise Cast)',
                  href: 'https://www.advertisecast.com/podcast-advertising-glossary'
                },
                {
                  text: 'Make Money From Your Podcast',
                  description: '(HowToMakeAPodcast.org)',
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-8/'
                },
                {
                  text: 'Podcast Pricing: CPM and CPA',
                  description: '(True Native Media)',
                  href: 'http://www.truenativemedia.com/podcast-pricing-cpm-and-cpa/'
                },
                {
                  text: "A Podcaster's Guide to Podcast Sponsorship Rates",
                  description: '(Backyard Media)',
                  href: 'https://backyardmedia.us/guides/2018/7/17/a-podcasters-guide-to-podcast-sponsorship-rates'
                },
                {
                  text: 'Everything You Need to Know about Podcast Advertising',
                  description: '(Cleverism)',
                  href: 'https://www.cleverism.com/everything-about-podcast-advertising/'
                },
                {
                  text: 'How to Break Into Podcast Advertising',
                  description: '(Megaphone)',
                  href: 'https://medium.com/megaphonepods/how-to-break-into-podcast-advertising-6268b8f309e'
                }
              ]}
            />
            <h3>Finding and working with sponsors</h3>
            <LinkList
              links={[
                {
                  text: 'Why we are introducing Paid Listens for podcasters',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/02/06/why-we-are-introducing-paid-listens-for-podcasters-on-radiopublic/'
                },
                {
                  text: 'A Guide to How to Get Sponsored for New Podcasts',
                  description: '(Backyard Media)',
                  href: 'https://backyardmedia.us/guides/2018/10/9/a-guide-to-how-to-get-sponsored-for-new-podcasts'
                },
                {
                  text: 'How To Do Podcast Sponsorship',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/monetisation/how-to-do-podcast-sponsorship/'
                },
                {
                  text: 'How to Monetize Your Podcast with Sponsors: Pitching, Pricing and Ad Sales 101',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/how-to-get-podcast-sponsors-72e3ee81c57d'
                }
              ]}
            />
            <h3>Ad formats</h3>
            <LinkList
              links={[
                {
                  text: 'What is a podcast ad?',
                  description: '(Backyard Media)',
                  href: 'https://backyardmedia.us/blog/2017/10/7/what-is-a-podcast-ad'
                },
                {
                  text: 'When Will Dynamic Ad Insertion Take Over Podcasting?',
                  description: '(Backyard Media)',
                  href: 'https://backyardmedia.us/blog/2018/1/24/when-will-dynamic-ad-insertion-take-over-podcasting'
                },
                {
                  text: 'How To Record Great Ads',
                  description: '(Anchor)',
                  href: 'https://medium.com/anchor/how-to-record-great-podcast-ads-using-anchor-sponsorships-20e7c917032b'
                },
                {
                  text: 'Midroll Vs Preroll: What’s The Best Place For Podcast Advertising?',
                  description: '(Power Press Podcast)',
                  href: 'https://powerpresspodcast.com/2017/04/28/midroll-vs-preroll-whats-best-place-podcast-advertising/'
                },
                {
                  text: 'Podcast Ads that Make Money',
                  description: '(Podchaser)',
                  href: 'https://blog.podchaser.com/industry/podcast-ads-that-make-money/'
                }
              ]}
            />
            <h2>From listeners</h2>
            <p>
              Support from your audience takes a few forms: directly through donations or one-time or ongoing payments, or by purchasing something
              related to your podcast, including merchandise, books and other media, or ticketed events. Depending your approach to how you ask your
              listeners for contributions, there can be an expectation that they’ll receive something else in addition to your podcast in return,
              whether it be swag or exclusive content.
            </p>
            <h3>Direct financial support</h3>
            <LinkList
              links={[
                {
                  text: '19 Patreon Rewards for Podcasters to Offer Their Fans',
                  description: '(Patreon)',
                  href: 'https://blog.patreon.com/19-patreon-rewards-for-podcasters-to-offer-their-fans'
                },
                {
                  text: 'Monetising a Podcast Through Donations & Patreon',
                  description: '(The Podcast Host)',
                  href:
                    'https://www.thepodcasthost.com/podcraft-podcast/series-5-making-money-in-podcasting/monetising-a-podcast-through-donations-patreon/'
                },
                {
                  text: 'Calls To Action: making it easier to earn money from your podcast audience',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/08/17/calls-to-action-making-it-easier-to-earn-money-from-your-podcast-audience/'
                },
                {
                  text: 'Support from listeners, like you: Announcing Listener Tips',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/10/03/support-from-listeners-like-you/'
                },
                {
                  text: 'Patreon — Setting you and your fans up for success, Part Zero',
                  description: '(Sean Howard)',
                  href: 'https://blog.usejournal.com/patreon-setting-you-and-your-fans-up-for-success-b85958d77a19'
                },
                {
                  text: 'The Nine Commandments of Patreon',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/the-nine-commandments-of-patreon-8a194bbcca30'
                },
                {
                  text: 'Our Patreon Growth Experiment',
                  description: '(Sean Howard)',
                  href: 'https://medium.com/@passitalong/our-patreon-growth-experiment-42e8e5969ba3'
                }
              ]}
            />
            <h3>Member-only and premium content</h3>
            <LinkList
              links={[
                {
                  text: 'How To Do Premium Podcast Content: Creating, Managing, & Selling',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/monetisation/how-premium-podcast-content/'
                },
                {
                  text: 'Make Money Podcasting: Premium Content',
                  description: '(Podcast Insights)',
                  href: 'https://www.podcastinsights.com/make-money-podcasting/#Premium_Content'
                },
                {
                  text: 'Premium Podcasting Content',
                  description: '(Blubrry)',
                  href: 'https://create.blubrry.com/resources/powerpress/advanced-tools-and-options/premium-podcasting-content/'
                },
                {
                  text: 'How to Create Premium Subscription Plans for Your Podcast',
                  description: '(Podbean)',
                  href: 'http://blog.podbean.com/2016/05/30/how-to-create-premium-subscription-plans-for-your-podcast/'
                },
                {
                  text: 'A Complete Subscription Management Service',
                  description: '(Libsyn)',
                  href: 'https://www.libsyn.com/my-libsyn-premium-content/'
                },
                {
                  text: 'How do I set up a patrons-only podcast on Patreon?',
                  description: '(Patreon)',
                  href: 'https://support.patreon.com/hc/en-us/articles/115004204206-How-do-I-set-up-a-Podcast-on-Patreon-'
                }
              ]}
            />
            <h3>Merch</h3>
            <LinkList
              links={[
                {
                  text: 'Podcast Merch | A Fun Way to Build Community, & Promote Your Show',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/promotion/podcast-merch/'
                }
              ]}
            />
            <h3>Events</h3>
            <LinkList
              links={[
                {
                  text: 'We’ll Do It Live: A DIY Guide to Podcast Live Shows',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/well-do-it-live-248d07957cd4'
                },
                {
                  text: 'Audio Danger: Going Live',
                  description: '(Nieman Storyboard)',
                  href: 'https://niemanstoryboard.org/stories/audio-danger-the-lessons-of-going-live/'
                }
              ]}
            />
          </article>
        </LearningNav>
      </Page>
    );
  }
}
