import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import Analytics from 'react-ga';

export const PODSITE_TRIAL_STORAGE_KEY = 'rp-podsiteTrialPromoCode';

export default class PodsiteTrialPromo extends Component<RouteComponentProps> {
  componentDidMount() {
    Analytics.pageview('/PM19');
    this._storeCode();
  }

  render() {
    return <Redirect to="/" />;
  }

  private _storeCode = () => localStorage.setItem(PODSITE_TRIAL_STORAGE_KEY, 'PM19');
}
