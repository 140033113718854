import React, { Component } from 'react';
import './styles.scss';

export default class SignupProgressIndicator extends Component {
  render() {
    return (
      <div className="progress-indicator">
        <div className="progress-nodes">
          <div className="progress-node-spacer" />
          <div className={`progress-node ${this.props.step >= 1 ? 'progress-active' : ''}`}></div>
          <div className={`progress-node-spacer ${this.props.step >= 2 ? 'progress-active' : 'progress-inactive'}`} />
          <div className={`progress-node ${this.props.step >= 2 ? 'progress-active' : ''}`}></div>
          <div className={`progress-node-spacer ${this.props.step >= 3 ? 'progress-active' : 'progress-inactive'}`} />
          <div className={`progress-node ${this.props.step >= 3 ? 'progress-active' : ''}`}></div>
          <div className="progress-node-spacer" />
        </div>
        <div className="progress-node-labels">
          <div className="progress-node-center-label">Verify</div>
          <div className="progress-node-side-labels">
            <div className="progress-node-side-label">Validation</div>
            <div className="progress-node-side-label">Terms of Service</div>
          </div>
        </div>
      </div>
    );
  }
}
