import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import ToolsNav from '../../components/ToolsNav';
import analyticsImage from '../../shared-assets/analytics.svg';
import makeItRainImage from '../../shared-assets/make-it-rain.svg';
import programmingFlagImage from '../../shared-assets/programming-flag.svg';
import laptopsBackgroundImage from './assets/laptops-desk-background.jpg';
import mobileMarketingImage from './assets/mobile-marketing.svg';
import './styles.scss';

export default class ToolsPage extends Component {
  componentDidMount() {
    Analytics.pageview('/tools');
  }

  render() {
    return (
      <Page className="tools-page" title="Free podcast resources from RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={laptopsBackgroundImage} arrowLinkHref="#discovery" justifyBackground="right">
          <h1>Tools for podcasters</h1>
          <p>We make tools that help your podcast grow and make money</p>
          <Link className="jumbo-round-link" to="/signup" onClick={this._handleTopLinkClick}>
            Get started
          </Link>
        </PanelWithBackdrop>
        <ToolsNav>
          <section id="discovery" className="find-show-section">
            <div>
              <div className="text-block">
                <h2>Help people find your show</h2>
                <p>
                  Creating a RadioPublic account adds your show to the RadioPublic search catalog and means any listener on the web, iOS, and Android
                  can find and hear your show.
                </p>
                <Link to="/signup">Create your free RadioPublic account</Link>
              </div>
              <div>
                <img src={analyticsImage} alt="A guy pulling up a chart on a giant computer." />
              </div>
            </div>
          </section>
          <section className="growth-section">
            <div>
              <div className="text-block">
                <div className="image-container">
                  <img src={programmingFlagImage} alt="A flag with programming symbols on it" />
                </div>
                <h2>Embed your podcast everywhere</h2>
                <p>
                  RadioPublic’s free embed player works for any podcast, any sized screen, anywhere on the web, anywhere in the world. It seamlessly
                  turns a casual visitor into a listener of your show.
                </p>
                <Link to="/embed">Learn about embeds</Link>
              </div>
            </div>
          </section>
          <section className="marketing-section">
            <div>
              <div>
                <div className="image-container">
                  <img src={mobileMarketingImage} alt="" />
                </div>
              </div>
              <div className="text-blocks">
                <div className="text-block">
                  <h2>Marketing tools</h2>
                  <p>
                    Fans can be your most powerful marketers, and our podcast marketing course helps them help you. And with Sonar Podcast Marketing™,
                    create marketing attribution links to measure your promotional efforts.
                  </p>
                  <Link to="/marketing">Learn about marketing tools</Link>
                </div>
                <hr></hr>
              </div>
            </div>
          </section>
          <section className="podfund-section">
            <div>
              <div className="text-block">
                <h2>Funding for early stage podcasters</h2>
                <p>
                  Podfund supports emerging creators building creative, profitable, and impactful podcast businesses with funding, mentorship, and
                  peer community.
                </p>
                <a href="https://pod.fund" target="_blank" rel="noopener noreferrer">
                  Learn about Podfund
                </a>
              </div>
              <div>
                <img src={makeItRainImage} alt='A person "making it rain" with money' />
              </div>
            </div>
          </section>
        </ToolsNav>
      </Page>
    );
  }
}
