/**
 * Component copied from react-router-hash-link and then modified to add support for smooth scrolling:
 * https://github.com/rafrex/react-router-hash-link
 */
import React from 'react';
import { Link } from 'react-router-dom';

let hashFragment = '';
let observer = null;
let asyncTimerId = null;
let scrollOptions = {};

function reset() {
  hashFragment = '';
  if (observer !== null) observer.disconnect();
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

function getElAndScroll() {
  const element = document.getElementById(hashFragment);
  if (element !== null) {
    element.scrollIntoView(scrollOptions);
    reset();
    return true;
  }
  return false;
}

function hashLinkScroll(behavior) {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }
      observer.observe(document, { attributes: true, childList: true, subtree: true });
      // if the element doesn't show up in 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, 10000);
    }
  }, 0);
}

export default function HashLink(props) {
  function handleClick(e) {
    reset();
    if (props.onClick) props.onClick(e);
    if (typeof props.to === 'string') {
      hashFragment = props.to
        .split('#')
        .slice(1)
        .join('#');
    } else if (typeof props.to === 'object' && typeof props.to.hash === 'string') {
      hashFragment = props.to.hash.replace('#', '');
    }
    if (hashFragment !== '') hashLinkScroll();
  }
  scrollOptions = { behavior: props.behavior || 'auto' };
  return (
    <Link {...props} onClick={handleClick}>
      {props.children}
    </Link>
  );
}
