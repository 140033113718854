/**
 * Constants used in the app.
 */

export const isProduction = process.env.NODE_ENV === 'production' && location.host.indexOf('staging') === -1; // eslint-disable-line no-restricted-globals

const config = {
  ABOUT_RADIOPUBLIC_URL: 'https://about.radiopublic.com/',
  ANCHOR_DASHBOARD_LOGIN_URL: 'https://anchor.fm/dashboard',
  APP_STORE_URL: 'https://itunes.apple.com/us/app/radiopublic-free-podcasts/id1113752736',
  FACEBOOK_URL: 'https://www.facebook.com/radiopublicpbc',
  GOOGLE_ANALYTICS_ID: isProduction ? 'UA-68317578-11' : '',
  HELP_URL: 'https://help.radiopublic.com/',
  HELP_EMAIL_ADDRESS: 'help@radiopublic.com',
  PAID_LISTENS_EMAIL_MISSING_HELP_URL: 'https://help.radiopublic.com/hc/en-us/articles/360000487733',
  PAID_LISTENS_FAQ_URL: 'https://help.radiopublic.com/hc/en-us/sections/360000056513-Paid-Listens',
  PAID_LISTENS_REQUIREMENTS_URL: 'https://help.radiopublic.com/hc/en-us/articles/360000553414-What-is-required-to-participate-in-Paid-Listens-',
  PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.radiopublic.android',
  PODCAST_FEED_VALIDATOR_URL: 'https://podba.se/validate/',
  PODCASTER_MARKETING_TOOLS_URL: 'https://help.radiopublic.com/hc/en-us/sections/360000104593-Marketing-your-podcast',
  PRIVACY_POLICY_URL: 'https://about.radiopublic.com/radiopublic-privacy-policy-549250c61572',
  PODSITE_UPGRADE_URL: 'https://forms.gle/YAabgJVewzqLL5hWA',
  RADIOPUBLIC_API_BASE_URL: isProduction ? 'https://api.radiopublic.com' : 'https://api-staging.radiopublic.com',
  RADIOPUBLIC_MAIN_SITE_URL: 'https://www.radiopublic.com',
  TERMS_OF_SERVICE_URL: 'https://about.radiopublic.com/radiopublic-terms-of-service-aeee2c4d56c5',
  STRIPE_CLIENT_ID: isProduction ? 'ca_CHeQVK8N4zpQvLqBcg5cXxnjjS2x39AX' : 'ca_CHeQzhVSqLcZUfoBUssPdgfdzTzdDQKW',
  TWITTER_URL: 'https://twitter.com/RadioPublic',
  WHAT_IS_RADIOPUBLIC_URL: 'https://about.radiopublic.com/welcome-to-radiopublic-62745019d63',
  WHY_PAID_LISTENS_URL: 'https://about.radiopublic.com/why-we-are-introducing-paid-listens-for-podcasters-on-radiopublic-c9e725f6593d'
};
export default config;
