export default `
## RadioPublic Podcaster Program Terms

Effective as of June 15, 2018

These terms (the “Podcaster Program Terms”) govern RadioPublic’s (“RadioPublic”) Podcaster Program (“Podcaster Program”). Through RadioPublic’s Podcaster Program, you can make ad-free podcast episodes available on RadioPublic and receive revenue based for Podcaster (“Podcaster”).

By participating in the Podcaster Program, you agree to these Podcaster Program Terms, as well as RadioPublic’s Terms of Service, Privacy Policy, and all other terms and conditions that generally apply to the RadioPublic website and mobile applications (all of these together are the “Terms”).

Please read the Podcaster Program Terms carefully. If you don’t understand them, or don’t accept any part of them, then you can’t participate in the Podcaster Program. Your online acceptance of RadioPublic’s Terms form a binding agreement between you and RadioPublic and show your intent to be bound by them.

### Content rights and responsibilities

You own the rights to the content you create and make available on RadioPublic.

We will never license or sell your content to third parties without your explicit permission.

You are responsible for the content you are publishing. This means you assume all risks related to it, including someone else’s reliance on its accuracy, or claims relating to intellectual property or other legal rights.

By making your content available on RadioPublic, you represent that doing so doesn’t conflict with any other agreement you’ve made.

You may only participate in the Podcaster Program if you have sufficient rights to make commercial use of the content in your podcast.

By making content available that you didn’t create on RadioPublic, you are representing that you have the right to do so. For example, you are posting a work that’s in the public domain, used under license (including a free license, such as Creative Commons), or a fair use.

The consequences of participating in the Podcaster Program when you lack the rights to do so or failing to provide adequate documentation upon request include withholding of revenue, suspension or termination from the Podcaster Program, suspension or termination of your RadioPublic account, and removal of your podcast from RadioPublic’s platform entirely.

These Terms are not legal advice. If you plan to monetize a podcast containing any content you did not create, you may want to talk to a lawyer first to ensure you own all rights required to do so.

We can remove any content you make available on RadioPublic for any reason.

You can remove any of your content at any time by contacting us at help@radiopublic.com. Processing the removal may take a little time, but we’ll do it as quickly as possible. We may keep backup copies of your deleted feeds on our servers for up to 14 days after you delete it.

### Communications

By using the Podcaster Program, you consent to receiving communications from us related to it, including marketing communications such as newsletters, special offers, promotional announcements, and customer surveys, to your registered email address or via other methods.

### Governing Law and Forum

The governing law, mandatory forum, and dispute resolution provisions of RadioPublic’s Terms of Service also apply to these Podcaster Program Terms and any dispute arising under them.

### Miscellaneous

You participate in the Podcaster Program at your own risk. You agree that you are solely responsible for your actions in connection with the Podcaster Program, any breach of your obligations under the Podcaster Program Terms, and for the consequences of any such breach.

Any capitalized terms used but not defined in these Podcaster Program Terms have the meanings given to them in RadioPublic’s Terms of Service. Except as modified by these Terms, RadioPublic’s Terms of Service remain in effect. RadioPublic’s right to modify or revise the Terms of Service also applies to these Podcaster Program Terms.

### Contact Us

If you have questions or feedback about the Podcaster Program, you can email at us at help@radiopublic.com

`;
