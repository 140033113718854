import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LinkData } from '..';
import AdaptableLink from '../../AdaptableLink';
import rpFlagImage from '../../../shared-assets/radiopublic-flag-red.svg';
import './styles.scss';

interface MobileMenuProps {
  menuSections: LinkData[];
  onDismiss: () => void;
}

export default class MobileMenu extends Component<MobileMenuProps> {
  render() {
    return (
      <nav className="mobile-menu-v2">
        <div className="menu-header">
          <Link to="/" onClick={this.props.onDismiss}>
            <img src={rpFlagImage} alt="RadioPublic logo" />
          </Link>
          <button onClick={this.props.onDismiss}>CLOSE</button>
        </div>
        <ul className="menu-content">{this.props.menuSections.map(this._renderLink)}</ul>
        <Link className="menu-footer" to="/signup">
          Create your FREE RadioPublic account
        </Link>
      </nav>
    );
  }

  private _renderLink = (link: LinkData) => {
    return (
      <li key={link.text}>
        <AdaptableLink to={link.url} onClick={this.props.onDismiss}>
          {link.text}
        </AdaptableLink>
      </li>
    );
  };
}
