import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import arrowSvg from './assets/arrow_rounded_right.svg';
import './styles.scss';

const LOCAL_STORAGE_KEY = 'rp-podcasterWebsiteTrialPromptState';

const DISALLOWED_ROUTES_REGEX = /\/()|(pricing|website|dashboard.*|login|signup)/;

interface PromptState {
  promptDismissed: boolean;
}

class PodcasterToastPromptBanner extends Component<RouteComponentProps> {
  state = { promptState: null as PromptState | null };

  componentDidMount() {
    this._initializePromptState();
  }

  render() {
    const { promptState } = this.state;
    const shouldShowPrompt = promptState && !promptState.promptDismissed && this._shouldRenderAtPath(); // there's no prompt state until after the component is mounted
    return (
      <div className={`podcaster-prompt-banner ${shouldShowPrompt ? 'expanded' : 'collapsed'}`}>
        <TransitionGroup>
          {<PromptContent {...this.props} visible={shouldShowPrompt || false} onCloseRequested={this._handleCloseClick} />}
        </TransitionGroup>
      </div>
    );
  }

  _handleCloseClick = () => this._updatePromptState({ promptDismissed: true });

  _initializePromptState() {
    let promptState = {};
    try {
      const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (serializedState) {
        promptState = JSON.parse(serializedState);
      }
    } catch (error) {
      console.log('Error deserializing prompt state: ' + error.message); // eslint-disable-line no-console
    }
    const defaultValues = {
      promptDismissed: false
    };
    promptState = Object.assign({}, defaultValues, promptState);
    this.setState({ promptState });
  }

  _updatePromptState(propsToUpdate) {
    const { promptState } = this.state;
    Object.assign(promptState, propsToUpdate);
    this.setState({ promptState });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(promptState));
  }

  private _shouldRenderAtPath(): boolean {
    const shouldShow = !DISALLOWED_ROUTES_REGEX.test(this.props.location.pathname);
    return shouldShow;
  }
}

interface PromptContentProps extends RouteComponentProps {
  visible: boolean;
  onCloseRequested: () => void;
}

/**
 * The actual content of the prompt is placed in this separate, inner component so its entry and
 * departure can be animated.
 */
class PromptContent extends Component<PromptContentProps> {
  state = { visible: false };

  componentWillMount() {
    setTimeout(() => this.setState({ visible: true }), 100);
  }

  componentDidMount() {
    Analytics.event({ category: 'CTA Modal', action: 'Display', nonInteraction: true });
  }

  componentWillUnmount() {
    Analytics.event({ category: 'CTA Modal', action: 'Dismiss', nonInteraction: true });
  }

  render() {
    return (
      <div className={`banner-content ${this.props.visible && this.state.visible ? 'visible' : ''}`}>
        <div className="banner-toast-view">
          <p>Your professional podcast website, set up in just minutes.</p>
          <Link className="banner-action-group" to="/website" onClick={this._handleToastAction}>
            <>
              <p>Start your 14-day trial FREE</p>
              <img className="banner-action-image" src={arrowSvg} alt="" />
            </>
          </Link>
        </div>
      </div>
    );
  }

  private _handleToastAction = () => {
    this.props.onCloseRequested();

    Analytics.event({
      category: 'cta',
      action: `modal - button`,
      label: `get started`
    });
  };
}

export default withRouter(PodcasterToastPromptBanner);
