import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import LearningNav from '../../components/LearningNav';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import analyticsImage from '../../shared-assets/analytics.svg';
import hangOutTableImage from '../../shared-assets/hang-out-table.svg';
import makeItRainImage from '../../shared-assets/make-it-rain.svg';
import fireworksImage from './assets/fireworks.svg';
import learnBackgroundImage from './assets/learn-background.jpg';
import './styles.scss';

export default class LearningPage extends Component {
  componentDidMount() {
    Analytics.pageview('/learn');
  }

  render() {
    return (
      <Page className="learning-page" title="How to make a podcast from your friends at RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel-base" backgroundImage={learnBackgroundImage} arrowLinkHref="#intro" aspectRatio="2/1">
          <h1>All you need to start a podcast and grow it</h1>
          <p>We make tools that help your podcast grow and make money</p>
        </PanelWithBackdrop>
        <LearningNav className="sections">
          <section className="reversed">
            <div className="text-block">
              <h2>Starting a podcast</h2>
              <p id="intro">
                Step out onto the podcasting scene with a little help from your friends at RadioPublic and resources from every corner of the
                internet. Whether you're backed by an experienced team or dipping your toe into the podcasting biz solo, you'll be well-equipped to
                begin your journey with these helpful tips walking you through conceptualization to launch and beyond.
              </p>
              <Link to="/start">Learn more</Link>
            </div>
            <div>
              <img src={fireworksImage} alt="People looking at fireworks" />
            </div>
          </section>
          <section>
            <div>
              <img src={analyticsImage} alt="A guy standing in front of a line graph." />
            </div>
            <div className="text-block">
              <h2>Improving a podcast</h2>
              <p>
                If you feel like your podcast needs a boost and you're wondering where to begin, these guides are here to assist. Enhance your
                production, elevate your web presence, and engage your listeners with compelling content.
              </p>
              <Link to="/improve">Learn more</Link>
            </div>
          </section>
          <section className="reversed">
            <div className="text-block">
              <h2>Growing audience</h2>
              <p>
                Assure your podcast can&mdash;and will&mdash;be found and heard by new listeners with this collection of marketing articles about
                owned earned media coverage as well as paid advertising.
              </p>
              <Link to="/grow">Learn more</Link>
            </div>
            <div>
              <img src={hangOutTableImage} alt="People hanging out around a table." />
            </div>
          </section>
          <section>
            <div>
              <img src={makeItRainImage} alt='A guy "making it rain" with dollars.' />
            </div>
            <div className="text-block">
              <h2>Making money</h2>
              <p>Let’s get your podcast revenue “in the black” with advice on how to earn money via outside support and from your listeners.</p>
              <Link to="/revenue">Learn more</Link>
            </div>
          </section>
        </LearningNav>
      </Page>
    );
  }
}
