import React from 'react';
import { Link } from 'react-router-dom';
import isUrl from '../../../utils/isUrl';
import './styles.scss';

interface LinkSectionsProps {
  sectionTitle: string;
  linkColumns: { text: string; path: string }[][];
}
export default function FooterLinkSection(props: LinkSectionsProps) {
  return (
    <div className="footer-links">
      <h2>{props.sectionTitle}</h2>
      <div className="links-group-container">
        {props.linkColumns.map((section, index) => {
          return (
            <div className="links-group" key={index}>
              {section.map(link => {
                return isUrl(link.path) ? (
                  <a href={link.path} key={link.path}>
                    {link.text}
                  </a>
                ) : (
                  <Link to={link.path} key={link.path}>
                    {link.text}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
