import React, { Component } from 'react';
import Analytics from 'react-ga';
import blobImage from './assets/blob.svg';
import doorImage from './assets/door.svg';
import cardImage from './assets/card.svg';
import messageImage from './assets/message.svg';
import './styles.scss';

interface EmailVerificationViewProps {
  feedUrl: string;
  emailAddresses: string[];
  onStartOver: () => void;
  onContinue: () => void;
}

export default class EmailVerificationView extends Component<EmailVerificationViewProps> {
  state = { showHelpSection: false };

  render() {
    return (
      <div className="email-verification-view">
        <div className="signup-page-step-number">Step 2</div>
        <h1>Email verification</h1>
        <div className="email-addresses-beginning">
          To verify your podcast on RadioPublic, we need to confirm you own the podcast by validating the email{' '}
          {this.props.emailAddresses.length > 1 ? 'addresses' : 'address'} found in your RSS feed. Here's what we found:
        </div>
        {this._renderEmailAddresses()}
        <div className="email-addresses-end">
          We're going to send an email to {this.props.emailAddresses.length > 1 ? 'all the addresses' : 'the address'} above.
          {this.props.emailAddresses.length > 1 && ` You may have access to all of them, but you only need to follow the instructions one time.`}
        </div>
        {this._renderHelpSection()}
        <div className="email-verification-buttons">
          <button onClick={this.props.onStartOver}>Start over</button>
          <button onClick={this.props.onContinue}>Send email</button>
        </div>
      </div>
    );
  }

  private _renderEmailAddress = emailAddress => {
    return (
      <div className="email-address" key={emailAddress}>
        {emailAddress}
      </div>
    );
  };

  private _renderEmailAddresses = () => {
    const { emailAddresses } = this.props;
    if (emailAddresses.length <= 2) {
      return emailAddresses.map(this._renderEmailAddress);
    }
    // There are more than two email addresses, so display them in a scroll view.
    return <div className="email-address-container">{emailAddresses.map(this._renderEmailAddress)}</div>;
  };

  private _renderHelpSection() {
    if (this.state.showHelpSection) {
      return (
        <div className="help-section">
          <button onClick={this._toggleHelpSection} className="button-css-reset">
            ×
          </button>
          <h2>Claim ownership of your podcast by adding your email</h2>
          <ol className="remove-list-styles">
            <li>
              <img src={doorImage} alt="door opening" />
              <h3>Step 1</h3>
              <p>Open a new browser tab and log in to your podcast host.</p>
            </li>
            <li>
              <div>
                <img src={blobImage} alt="" />
                <img className="card" src={cardImage} alt="a card with info on it" />
              </div>
              <h3>Step 2</h3>
              <p>Add your email address to your podcast description.</p>
            </li>
            <li>
              <img src={messageImage} alt="a man opening a giant envelope" />
              <h3>Step 3</h3>
              <a onClick={this.props.onStartOver}>Re-verify your podcast</a>
            </li>
          </ol>
        </div>
      );
    }
    return <a onClick={this._toggleHelpSection}>I don’t see my email address here, what do I do?</a>;
  }

  private _toggleHelpSection = () => {
    Analytics.event({
      category: 'Signup',
      action: 'Need help',
      label: this.props.feedUrl
    });

    this.setState({ showHelpSection: !this.state.showHelpSection });
  };
}
