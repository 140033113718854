import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import config from '../../config';
import hangOutTableImage from '../../shared-assets/hang-out-table.svg';
import makeItRainImage from '../../shared-assets/make-it-rain.svg';
import LearningGuidePromo from '../../components/LearningGuidePromo';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import ToolsNav from '../../components/ToolsNav';
import appScreensImage from './assets/app-screens.png';
import appStoreImage from './assets/app-store.svg';
import backgroundBlobImage from './assets/background-blob.svg';
import danceImage from './assets/dance.svg';
import hearmarkImage from './assets/hearmark.svg';
import lightningImage from './assets/lightning.svg';
import notesImage from './assets/notes.svg';
import phoneImportImage from './assets/phone-import.svg';
import phonePeopleBackgroundImage from './assets/phone-people-background.jpg';
import playImage from './assets/play.svg';
import playStoreImage from './assets/play-store.svg';
import repeatImage from './assets/repeat.svg';
import rocketImage from './assets/rocket.svg';
import seriesImage from './assets/series.svg';
import skipImage from './assets/skip.svg';
import starImage from './assets/star.svg';
import trailerImage from './assets/trailer.svg';
import './styles.scss';

export default class AppPage extends Component {
  componentDidMount() {
    Analytics.pageview('/app');
  }

  render() {
    return (
      <Page className="app-page" title="Free podcast apps for iPhone and Android from RadioPublic" useV2Header={true}>
        <PanelWithBackdrop className="top-panel" backgroundImage={phonePeopleBackgroundImage} arrowLinkHref="#intro" aspectRatio="2/1">
          <h1>The podcast app helping you grow</h1>
          <p>Control how your podcast looks in the RadioPublic apps for iPhone and Android</p>
          <Link className="jumbo-round-link" to="/signup">
            Get started
          </Link>
        </PanelWithBackdrop>
        <ToolsNav>
          <section className="intro-section">
            <div>
              <h2>Easy for listeners to get started</h2>
              <p>
                The free RadioPublic app makes it quick and easy for listeners to get to your show in any country. And with one universal link, your
                podcast is available for anyone on an Android or iPhone worldwide–and that’s only the start.
              </p>
              <ul>
                <li>
                  <div>
                    <img className="lightning" src={lightningImage} alt="lightning bolt" />
                  </div>
                  <div>
                    <h3 id="intro">The fastest episode updates</h3>
                    <p>
                      Listeners automatically follow your show during the app install process, ensuring they get updates when you publish new episode
                      with no delays.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src={rocketImage} alt="rocket ship" />
                  </div>
                  <div>
                    <h3>Quick to get started</h3>
                    <p>
                      Listeners get to your show faster in RadioPublic. There’s no lost time setting up an account or social login before they can
                      begin listening.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src={phoneImportImage} alt="phone import icon" />
                  </div>
                  <div>
                    <h3>Import from other apps</h3>
                    <p>
                      We aid podcast listeners in exporting their shows from another podcast listening app over to RadioPublic with an easy to
                      import/export OPML file.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src={playImage} alt="play icon" />
                  </div>
                  <div>
                    <h3>Sample, then subscribe</h3>
                    <p>
                      On the web, turn a casual visitor into a fan with your universal RadioPublic link and in the app, a curious podcast listener can
                      just press play on an episode to get hooked.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <img src={repeatImage} alt="infinity symbol" />
                  </div>
                  <div>
                    <h3>Focused on repeat listening</h3>
                    <p>
                      Once a listener is following your show, the app encourages continuous listening, resurfaces your show with Radio Mode, and
                      offers reminders to revisit older, unheard, or unfinished episodes.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <LearningGuidePromo
              className="learning-guide"
              linkHref="https://help.radiopublic.com/hc/en-us/articles/360009011514-A-guide-to-creating-a-how-to-listen-guide"
              image={hangOutTableImage}
              title="Attract new listeners"
            >
              Assure that all new podcast listeners know how to hear your podcast with this step-by-step How To Listen guide.
            </LearningGuidePromo>
          </section>
          <section className="feature-section">
            <div className="background-blob">
              <img src={backgroundBlobImage} alt="" />
            </div>
            <div>
              <div className="app-screens">
                <img src={appScreensImage} alt="RadioPublic app screens" />
              </div>
              <div className="app-store-links">
                <a href={config.APP_STORE_URL} target="_blank" rel="noopener noreferrer">
                  <img src={appStoreImage} alt="Download on the Apple App Store" />
                </a>
                <a href={config.PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
                  <img src={playStoreImage} alt="Download on the Google Play Store" />
                </a>
              </div>
              <h2>Making your podcast shine in RadioPublic</h2>
              <p>We created app features that help you put your best foot forward for new listeners.</p>
              <ul>
                <li>
                  <img src={starImage} alt="star" />
                  <h3>Featured episodes</h3>
                  <p>Flag an episode or two you always want front and center.</p>
                </li>
                <li>
                  <img src={notesImage} alt="notes" />
                  <h3>Show notes</h3>
                  <p>We display formatted show notes in full, including clickable links.</p>
                </li>
                <li>
                  <img src={seriesImage} alt="" />
                  <h3>Series</h3>
                  <p>Organize your seasons for quick listening access.</p>
                </li>
                <li>
                  <img src={trailerImage} alt="" />
                  <h3>Trailers</h3>
                  <p>Make a great first impression with your show’s teaser.</p>
                </li>
                <li>
                  <img src={skipImage} alt="audio skip forward symbol" />
                  <h3>Chapters</h3>
                  <p>Listeners can easily jump to key moments in an episode, preselected by you.</p>
                </li>
                <li>
                  <img src={hearmarkImage} alt="RadioPublic HearMark™ logo" />
                  <h3>HearMarks™</h3>
                  <p>Bookmark moments in an episode while listening to share with others.</p>
                </li>
              </ul>
            </div>
          </section>
          <section className="generic-section">
            <div>
              <img src={danceImage} alt="girl dancing in front of a giant cell phone" />
            </div>
            <div>
              <h2>Making podcasts more discoverable</h2>
              <p>
                Sharing your show helps it grow: RadioPublic makes it easy to seamlessly share your podcast on the web and mobile. We also regularly
                surface shows through hand-curated editorial features and lean-back listening experiences.
              </p>
              <ul>
                <li>
                  <h3>Sharing your show</h3>
                  <p>Share exactly the moment to start listening, via text, email, social media, or an embed player.</p>
                </li>
                <li>
                  <h3>Editorial features</h3>
                  <p>Our Explore section highlights podcasts big and small, organized by how listeners are looking for them.</p>
                </li>
                <li>
                  <h3>Playlists</h3>
                  <p>Hand-picked episodes from podcast experts, our Podcast Librarian, and listeners like you.</p>
                </li>
                <li>
                  <h3>Stations</h3>
                  <p>Choose a station by topic or time of day, then kick back to enjoy what comes up next from our hand-curated selections.</p>
                </li>
                <li>
                  <h3>Radio mode</h3>
                  <p>Run out of episodes in your queue? We’ll suggest others for you to sample.</p>
                </li>
              </ul>
            </div>
          </section>
          <section className="generic-section reversed">
            <div>
              <h2>Helping you make money</h2>
              <p>
                RadioPublic recognizes that while podcasts are free to listeners, every podcast costs money to make. Break even (and earn more) with
                our revenue opportunities.
              </p>
              <ul>
                <li>
                  <h3>Paid Listens</h3>
                  <p>
                    Earn money every time someone hears an episode of your podcast on RadioPublic - $20 for every 1,000 listens, regardless of how
                    many listeners you have.
                  </p>
                </li>
                <li>
                  <h3>Calls to action</h3>
                  <p>Target messages that reach the right listeners at the right moment.</p>
                </li>
                <li>
                  <h3>Tipping</h3>
                  <p>Receive one-time financial contributions from fans, right in the app.</p>
                </li>
                <li>
                  <h3>Sonar Podcast Marketing™</h3>
                  <p>Test and measure the results of your promotional campaigns with marketing attribution links.</p>
                </li>
              </ul>
            </div>
            <div>
              <img src={makeItRainImage} alt="girl dancing in front of a giant cell phone" />
            </div>
          </section>
        </ToolsNav>
      </Page>
    );
  }
}
