import React from 'react';
import LinksNav from './LinksNav';

const links = [
  {
    text: 'Starting a podcast',
    href: '/start'
  },
  {
    text: 'Improving your podcast',
    href: '/improve'
  },
  {
    text: 'Growing your podcast',
    href: '/grow'
  },
  {
    text: 'Making money',
    href: '/revenue'
  }
];

const LearningNav = props => <LinksNav links={links} {...props} />;
export default LearningNav;
