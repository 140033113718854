import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rpFlagImage from '../../shared-assets/radiopublic-flag-red-32px.svg';
import MobileMenu from './MobileMenu';
import caretImage from './assets/caret.svg';
import './styles.scss';
import { SidebarLinkSection } from '../../containers/PodsiteDashboardPage/SidebarNav';

interface PageHeaderProps {
  menuSections?: SidebarLinkSection[];
  simplifiedHeader?: boolean;
  publishError?: any;
}

export default class PageHeader extends Component<PageHeaderProps> {
  state = { showMobileMenu: false };

  private helpSection = {
    title: 'Help',
    tabs: [
      {
        text: 'Get help',
        url: 'https://help.radiopublic.com/hc/en-us/categories/115000401534-For-Podcasters'
      }
    ]
  };

  render() {
    return (
      <nav className="page-header">
        <div className={`page-header-container ${this.props.simplifiedHeader ? 'page-header-simplified' : ''}`}>
          <div className="rp-logo">
            <Link to="/">
              <img src={rpFlagImage} alt="RadioPublic" />
            </Link>
          </div>
          {this._renderUncollapsedHeaderLinks()}
          {this._renderMenuButton()}
        </div>
        {this.state.showMobileMenu && (
          <MobileMenu
            menuSections={this.props.menuSections || []}
            onDismiss={this._handleMobileMenuDismissal}
            publishError={this.props.publishError}
          />
        )}
      </nav>
    );
  }

  private _handleMobileMenuDismissal = () => this.setState({ showMobileMenu: false });

  private _handleMenuButtonClick = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  private _renderMenuButton() {
    if (!this.props.simplifiedHeader) {
      return (
        <button onClick={this._handleMenuButtonClick}>
          Menu
          <img src={caretImage} alt="" />
        </button>
      );
    }
  }

  private _renderUncollapsedHeaderLinks() {
    if (!this.props.simplifiedHeader) {
      const link = this.helpSection.tabs[0];
      return (
        <div className="uncollapsed-header-links">
          <a className="header-link" href={link.url}>
            {link.text}
          </a>
        </div>
      );
    }
  }
}
