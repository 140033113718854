import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import Analytics from 'react-ga';
import { render } from 'react-snapshot';
import ScrollToTop from './components/ScrollToTop';
import config from './config';
import './index.scss';
import polyfillArrayIncludes from './utils/polyfillArrayIncludes';
import App from './App';

Analytics.initialize(config.GOOGLE_ANALYTICS_ID, { gaOptions: { storage: 'none' } });
smoothscroll.polyfill();
polyfillArrayIncludes();
render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);
