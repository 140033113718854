import React, { Component } from 'react';
import Checkmark from '../../../components/Checkmark';
import './styles.scss';

export default class PodcastVerificationSuccessView extends Component {
  render() {
    return (
      <div className="podcast-verification-success-view">
        <Checkmark />
        <h1>Hooray!</h1>
        <p>Everything checks out fine with your podcast.</p>
        <button onClick={this.props.onContinue}>Next</button>
      </div>
    );
  }
}
