/**
 * This module exports functions to access the RouteComponentProps (e.g. props.history)
 * from a non-component context.
 *
 * You're supposed to be able to access the history in a non-component context using
 * [this approach](https://stackoverflow.com/questions/42672842/how-to-get-history-on-react-router-v4),
 * but in practice, I found that calling push() or replace() on that object would update
 * the URL but not actually cause react router to route to the matching component. So,
 * I made this workaround, which is hacky but works.
 */
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

let instance = null as RouterContext | null;

export function getHistory() {
  return instance!.props.history;
}

class RouterContext extends Component<RouteComponentProps> {
  componentWillMount() {
    instance = this;
  }

  render() {
    return <span />;
  }
}

export default withRouter(RouterContext);
