import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import Page from '../../components/Page';
import Spinner from '../../components/Spinner';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import PodcasterService from '../../services/PodcasterService';
import './styles.scss';
import qs from 'qs';

// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default class Login extends Component<RouteComponentProps> {
  state = this._initialState;

  private get _initialState() {
    return {
      email: '',
      emailIsValid: false,
      isLoading: false,
      errorMessage: '',
      isFinished: false,
      location: ''
    };
  }

  componentDidMount() {
    Analytics.pageview('/login');
    this._parseAndRemoveQueryParams();
  }

  _parseAndRemoveQueryParams() {
    const queryString = this.props.location.search[0] === '?' ? this.props.location.search.slice(1, this.props.location.search.length) : '';
    const params = qs.parse(queryString);

    if (params.location) {
      this.setState({ location: params.location });
      delete params.location;
      this.props.history.replace('/login');
    }
  }

  render() {
    return (
      <Page useV2Header={true}>
        <PanelWithBackdrop>
          <div className="login-page">
            <div className="login-modal">{this._renderView()}</div>
          </div>
        </PanelWithBackdrop>
      </Page>
    );
  }

  private _handleEmailChange = event => {
    const email = event.target.value;
    this.setState({ email, emailIsValid: EMAIL_REGEX.test(email) });
  };

  private _handleKeyDown = event => {
    if (event.key === 'Enter' && this.state.emailIsValid) {
      this._submitLoginEmail();
    }
  };

  private _handleOkButtonClick = () => {
    this.setState(this._initialState);
  };

  private _submitLoginEmail = async () => {
    try {
      this.setState({ isLoading: true });
      Analytics.event({ category: 'Login', action: 'Send Login Email' });
      const userExists = await PodcasterService.initiateLogin({ email: this.state.email, location: this.state.location });
      if (userExists) {
        this.setState({ isLoading: false, isFinished: true });
      } else {
        this.setState({
          errorMessage: `The email address you provided doesn't match any verified podcasts.`,
          isLoading: false
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: 'An error occurred while sending your login email. Please try again later.',
        isLoading: false
      });
    }
  };

  private _renderMessage = () => {
    if (this.state.errorMessage) {
      return <div className="login-error">{this.state.errorMessage}</div>;
    }
    return <p>Enter the email address associated with your podcast, and we’ll send a magic link to your inbox.</p>;
  };

  private _renderView = () => {
    if (this.state.isLoading) {
      return (
        <div className="login-loading-view">
          <h1>Loading</h1>
          <Spinner color="#3e4c75" size="34px" thickness="8px" />
        </div>
      );
    }

    if (this.state.isFinished) {
      return (
        <div className="login-finished-view">
          <h1>Check your inbox</h1>
          <p>We just emailed a confirmation link to {this.state.email}.</p>
          <p>Click the link, and you’ll be signed in.</p>
          <button className="theme-button" onClick={this._handleOkButtonClick}>
            OK
          </button>
        </div>
      );
    }

    return (
      <div className="login-view">
        <h1>Log In</h1>
        {this._renderMessage()}
        <div className="login-input">
          <label>Your email</label>
          <div className="login-input-container">
            <input
              type="email"
              placeholder="email@example.com"
              onChange={this._handleEmailChange}
              onKeyDown={this._handleKeyDown}
              value={this.state.email}
            />
          </div>
        </div>
        <button className="theme-button" disabled={!this.state.emailIsValid} onClick={this._submitLoginEmail}>
          Continue
        </button>
        <Link className="login-signup-link" to="/signup">
          Don't have an account?
        </Link>
      </div>
    );
  };
}
