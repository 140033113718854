import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import HashLink from './HashLink';

/**
 * A link component that can adapt to use <Link>, <HashLink>, or <a>
 * based on the URL.
 */
const AdaptableLink = (props: LinkProps) => {
  if (!props.to) {
    return <a {...props} />;
  }
  if (typeof props.to !== 'string') {
    return <Link {...props} />;
  }
  const url = props.to.toLowerCase();
  if (url.substr(0, 7) === 'http://' || url.substr(0, 8) === 'https://') {
    return <a href={props.to} {...props} />;
  }
  if (url.indexOf('#') !== -1) {
    return <HashLink behavior="smooth" {...props} />;
  }
  return <Link {...props} />;
};

export default AdaptableLink;
