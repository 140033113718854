import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import LearningNav from '../../components/LearningNav';
import LinkList from '../../components/LinkList';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import earbudsBackgroundImage from './assets/earbuds-background.jpg';
import './styles.scss';

export default class StartPage extends Component {
  componentDidMount() {
    Analytics.pageview('/start');
  }

  render() {
    return (
      <Page className="start-page" title="How to start a podcast from your friends at RadioPublic" useV2Header={true}>
        <PanelWithBackdrop
          className="top-panel-base"
          backgroundImage={earbudsBackgroundImage}
          justifyBackground="right"
          arrowLinkHref="#intro"
          aspectRatio="2/1"
        >
          <h1>How to start a podcast</h1>
          <p>So you want to start a podcast...Congrats! We are super excited hear what you create.</p>
        </PanelWithBackdrop>
        <LearningNav className="sections">
          <article>
            <p>
              You might feel very alone right now, but trust us, you're not. A recent count says there are more than 600,000 podcasts, with 30,000 new
              shows added each month, and believe it or not, those all started somewhere. Just because lots of people are creating podcasts doesn't
              mean it's easy, but never fear! We've scoured the web to highlight plenty of trusted resources that'll help guide you every step of the
              way, from naming your show to promoting your published episodes.
            </p>
            <p>
              Let's start with the <em>very</em> basics:
            </p>
            <h3 id="intro">
              What even <em>is</em> a podcast?
            </h3>
            <p>
              Podcasts were born of the internet, so its definition is a wee bit technical. Technical and important, but certainly not scary. A
              podcast is a special type of RSS feed that contains audio or video files, and these feeds make it so people can publish files to other
              people automatically. RSS stands for “Really Simple Syndication” - and it does exactly what the title says: this feed format makes it
              really easy for the content it contains to be distributed. Not one person or organization owns podcasts, anyone can both create them{' '}
              <em>and</em> consume them, and{' '}
              <a href="https://about.radiopublic.com/2016/10/04/why-podcasting-still-needs-rss/">that is central to their importance and beauty</a>.
            </p>
            <p>
              Anytime a podcaster publishes a new episode it is added to their show’s RSS feed, and is automatically sent to podcatchers (an
              application that can read RSS feeds, <Link to="/app">we make a great one</Link>!) so listeners everywhere can download or stream your
              podcast on their phone or computer.
            </p>
            <p>And the name? “Pod” refers to an early device to access these small pieces of content (the iPod) and “Cast” comes from “broadcast.”</p>
            <h2>Before you start: Conceptualizing your show</h2>
            <p>
              Now that you’re clear (if you weren’t already) about what a podcast is, let’s jump in on how to start. Before you do <em>anything</em>,
              orient yourself with these words of wisdom from pros about conceptualization of your show, what pitfalls to avoid, and whether a podcast
              is even the right format for your project (better to find this one out sooner rather than later).
            </p>
            <p>
              <i>
                If you’re short on time, or just looking for a general overview of the entire podcast creation experience,{' '}
                <a href="https://anchor.fm/how-to-start-a-podcast">this explainer from Anchor</a> takes you through concept, production, publication,
                promotion, and growth all in one succinct guide.
              </i>
            </p>
            <LinkList
              links={[
                {
                  href: 'https://training.npr.org/audio/so-you-want-to-start-a-podcast-read-this-first/',
                  text: 'Want to start a podcast? Read this first',
                  description: '(NPR)'
                },
                {
                  href: 'https://podcastwebsites.com/2017/03/why-you-shouldnt-start-a-podcast/',
                  text: 'You Might Not Be Ready to Start a Podcast',
                  description: '(PodcastWebsites.com)'
                },
                {
                  href: 'https://www.weeditpodcasts.com/the-biggest-challenges-podcasters-face/',
                  text: 'The Biggest Challenges Podcasters Face',
                  description: '(We Edit Podcasts)'
                },
                {
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-1/',
                  text: 'You Have an Idea! Should It Be a Podcast?',
                  description: '(HowToMakeAPodcast.org)'
                },
                {
                  href: 'https://medium.com/@alexkapelman/the-most-important-question-to-ask-yourself-about-your-podcast-f3e4e31591c0',
                  text: 'The Most Important Question To Ask Yourself About Your Podcast',
                  description: '(Alex Kapelman)'
                },
                {
                  href: 'https://electricliterature.com/how-to-write-a-fiction-podcast/',
                  text: 'How to Write a Fiction Podcast',
                  description: '(Electric Literature)'
                }
              ]}
            />
            <h2>Pre-production</h2>
            <p>
              Okay. So, you’ve got a concept. You know that a podcast is the right medium for this project. Now it’s time to...hit record? We’re
              close: planning and pre-production awaits you next.
            </p>
            <p>
              This is your chance to define your audience, identify what success means for your show, conduct your research, sketch out your
              production schedule, name your show, and more. These are essential next steps, and while it sounds like a lot, as your
              friendly&mdash;perhaps a little nosy&mdash;next-door neighbors we must remind you that failing to plan is planning to fail. And we
              definitely want you to succeed.
            </p>
            <h3>Ideas and planning</h3>
            <LinkList
              links={[
                {
                  href: 'https://bellocollective.com/more-pre-pro-less-problems-4a12b2cfb687',
                  text: 'More Pre-Pro, Less Problems',
                  description: '(Bello Collective)'
                },
                {
                  href: 'https://docs.google.com/document/d/1NOEu3tfo2jWf7R3q3vh7i-dJ4gy-ExHvDSjE0CY1O98/edit',
                  text: 'A blueprint for planning storytelling projects',
                  description: '(NPR) <em>Take a look at the fourth question for sure - it’s a fun Mad Lib!</em>'
                },
                {
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-2/',
                  text: 'What Kind of Podcast?',
                  description: '(HowToMakeAPodcast.org)'
                },
                {
                  href:
                    'https://airmedia.org/the-alex-kapelman-guide-to-the-creation-of-a-dope-project-9-tips-to-keep-indie-podcasters-happy-sane-and-moving-forward/',
                  text: 'The Alex Kapelman Guide to the Creation of a Dope Project: 9 Tips to keep indie podcasters happy, sane and moving forward',
                  description: '(AIR)'
                },
                {
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-3/',
                  text: 'Building a Team, Even If It’s Just You',
                  description: '(HowToMakeAPodcast.org)'
                },
                {
                  href: 'https://blog.pacific-content.com/the-art-and-science-of-naming-your-podcast-58399308066a',
                  text: 'The art and science of naming your podcast',
                  description: '(Pacific Content)'
                }
              ]}
            />
            <h3>Creative</h3>
            <LinkList
              links={[
                {
                  href: 'https://www.thepodcasthost.com/planning/podcast-names/',
                  text: 'Podcast Names: What Should I Call My Podcast?',
                  description: '(The Podcast Host)'
                },
                {
                  href:
                    'https://www.forbes.com/sites/mollyfordbeck/2018/08/09/the-template-you-need-to-write-your-podcast-show-description/#3c0d718d5064',
                  text: 'The Template You Need to Write Your Podcast Show Description',
                  description: '(Forbes)'
                },
                {
                  href: 'https://www.thepodcasthost.com/planning/less-is-more-designing-great-podcast-art',
                  text: 'Less is More: Designing Great Podcast Art',
                  description: '(The Podcast Host)'
                }
              ]}
            />
            <h3>Technology</h3>
            <LinkList
              links={[
                {
                  href: 'https://www.thepodcasthost.com/websites-hosting/best-podcast-hosting/',
                  text: 'The Best Podcast Hosting Services: Where to Host Your Podcast',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.weeditpodcasts.com/8-top-podcast-hosting-companies/',
                  text: '21 of the Top Podcast Hosting Companies',
                  description: '(We Edit Podcasts)'
                },
                {
                  href: 'https://transom.org/2016/podcasting-basics-part-4-hosting-distribution/',
                  text: 'Podcasting Basics, Part 4: Hosting & Distribution',
                  description: '(Transom)'
                },
                {
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360000504994-Using-RadioPublic-s-free-embed-player',
                  text: 'Using RadioPublic’s free embed player',
                  description: '(RadioPublic)'
                },
                {
                  href: 'https://resonaterecordings.com/2019/06/podcast-hosting/',
                  text: 'Top 5 Podcast Hosting Platforms',
                  description: '(Resonate Recordings)'
                }
              ]}
            />
            <h3>Funding</h3>
            <LinkList
              links={[
                {
                  href: 'https://airmedia.org/pragmatists-guide-podcasts-and-money/',
                  text: 'The Pragmatist’s Guide to Podcasts and Money',
                  description: '(AIR)'
                },
                {
                  href: 'https://vimeo.com/303602096',
                  text: 'Google Podcast creator program Budget Webinar',
                  description: '(Google Podcast creator program and PRX)'
                },
                {
                  href: 'https://www.neh.gov/grants/public/media-projects-production-grants',
                  text: 'Media Projects: Production Grants',
                  description: '(National Endowment for the Humanities)'
                },
                {
                  href: 'https://go.greaterpublic.org/blog/how-to-monetize-your-podcast-with-grants',
                  text: 'How to Monetize Your Podcast With Grants',
                  description: '(Greater Public)'
                }
              ]}
            />
            <h2>Production</h2>
            <p>
              Reap the sweet reward of all that pre-production and planning with a smooth transition into production. Here, you’ll find tips, tricks,
              and advice from the most seasoned radio makers and podcast creators about writing scripts, structuring stories, choosing equipment, and
              voicing.
            </p>
            <h3>Making a trailer</h3>
            <LinkList
              links={[
                {
                  href: 'https://bellocollective.com/coming-soon-to-headphones-near-you-how-to-make-a-trailer-520457ed9c6d',
                  text: 'Five Steps to Making a Memorable Audio Trailer',
                  description: '(Bello Collective)'
                },
                {
                  href: 'https://blog.pacific-content.com/every-podcast-needs-a-trailer-89f2f69c9847',
                  text: 'Every podcast needs a trailer',
                  description: '(Pacific Content)'
                }
              ]}
            />
            <h3>Writing</h3>
            <LinkList
              links={[
                {
                  href: 'https://www.thepodcasthost.com/planning/podcast-scripting/',
                  text: 'Podcast Scripting: How Do I Write a Podcast Script?',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.thepodcasthost.com/planning/the-invisible-podcast-script/',
                  text: 'The Art of Creating an Invisible Podcast Script',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://training.npr.org/audio/what-does-a-radio-script-look-like/',
                  text: 'What does a radio script look like?',
                  description: '(NPR)'
                },
                {
                  href: 'https://training.npr.org/audio/would-you-say-it-that-way-tips-on-writing-for-your-voice/',
                  text: '"Would you say it that way?" Tips on writing for your voice',
                  description: '(NPR)'
                },
                {
                  href: 'https://training.npr.org/audio/how-audio-stories-begin/',
                  text: 'How audio stories begin',
                  description: '(NPR)'
                },
                {
                  href: 'https://www.buzzsprout.com/blog/podcast-introduction',
                  text: 'Learn How To Create A Podcast Intro Your Listeners Will Love',
                  description: '(Buzzsprout)'
                },
                {
                  href: 'https://transom.org/2018/anatomy-of-a-scene/',
                  text: 'Anatomy of a Scene',
                  description: '(Transom)'
                },
                {
                  href: 'https://bellocollective.com/how-to-build-a-compelling-world-in-your-fiction-podcast-ed7c97d738ef',
                  text: 'How to Build a Compelling World in Your Fiction Podcast',
                  description: '(Bello Collective)'
                }
              ]}
            />
            <h3>Hardware & Software</h3>
            <LinkList
              links={[
                {
                  href: 'https://medium.com/@alexkapelman/the-one-kit-you-should-buy-to-make-a-podcast-a63d804c3514',
                  text: 'The One Kit You Should Buy To Make A Podcast',
                  description: '(Alex Kapelman)'
                },
                {
                  href: 'https://transom.org/2015/podcasting-basics-part-1-voice-recording-gear/',
                  text: 'Podcasting Basics, Part 1: Voice Recording Gear',
                  description: '(Transom)'
                },
                {
                  href: 'https://transom.org/2015/podcast-basics-part-2-software/',
                  text: 'Podcasting Basics, Part 2: Software',
                  description: '(Transom)'
                },
                {
                  href: 'https://transom.org/2012/field-gear-good-better-best/',
                  text: 'Field Gear: Good, Better, Best',
                  description: '(Transom)'
                },
                {
                  href: 'https://www.podcastinsights.com/podcast-starter-kit/',
                  text: 'The Best Podcaster Starter Kit (For 1, 2, 3 & 4+ Hosts)',
                  description: '(Podcast Insights)'
                },
                {
                  href: 'https://www.thepodcasthost.com/equipment/the-best-podcasting-microphones-on-the-market/',
                  text: 'The Best Podcast Microphones on the Market',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.thepodcasthost.com/equipment/podcast-mixers/',
                  text: 'The Best Podcast Mixers: Pros, Cons, & the Ones to Buy',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.thepodcasthost.com/equipment/best-digital-podcast-recorders/',
                  text: 'The Best Digital Podcast Recorders on the Market',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.thepodcasthost.com/equipment/interfaces/audio-interfaces-for-voice-podcasting/',
                  text: 'A Guide to Audio Interfaces for Voice & Podcasting',
                  description: '(The Podcast Host)'
                },
                {
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-4/',
                  text: 'The Best Gear Money Can Buy',
                  description: '(HowToMakeAPodcast.org)'
                }
              ]}
            />
            <h3>The process</h3>
            <LinkList
              links={[
                {
                  href: 'https://www.forbes.com/sites/sarahrheawerner/2016/12/31/a-step-by-step-walkthrough-of-my-podcast-process/#b3c759a13bf1',
                  text: 'A Step-By-Step Walkthrough Of My Podcast Process',
                  description: '(Forbes)'
                }
              ]}
            />
            <h2>Perfecting your web presence</h2>
            <p>
              If you want your podcast to be the best it can be, an effective web presence is a not-so-secret ingredient for success. Submit your
              podcast to podcast directories and highlight podcatchers that help new listeners hear your show. Make your website as user-friendly as
              possible, and utilize social media to engage your listeners. These guides break down what’ll help bring eyes and ears to your show.
            </p>
            <LinkList
              links={[
                {
                  href: 'https://about.radiopublic.com/category/for-podcasters/podsites-podcast-websites/how-to-make-a-great-podcast-website/',
                  text: 'How to make a great podcast website',
                  description: '(RadioPublic)'
                },
                {
                  href: 'https://bulletin.bigpodcast.com/podcast-directories-where-to-submit-your-podcast?ck_subscriber_id=201321576',
                  text: 'Podcast Directories - Where To Submit Your Podcast',
                  children: (
                    <span>
                      (Big Podcast){' '}
                      <i>
                        Want to submit to RadioPublic? <Link to="/signup">Here you go.</Link>
                      </i>
                    </span>
                  )
                },
                {
                  href: 'https://support.libsyn.com/faqs/directories/',
                  text: 'Knowledge Base for Podcast Directories',
                  description: '(Libsyn)'
                },
                {
                  href: 'https://www.podcastinsights.com/best-podcast-apps/',
                  text: 'Best Podcast Listening Apps (For iOS & Android) 2018',
                  description: '(Podcast Insights)'
                },
                {
                  href: 'https://blog.pacific-content.com/how-to-build-a-website-for-your-podcast-e9b59efcf395',
                  text: 'How to build a website for your podcast',
                  description: '(Pacific Content)'
                },
                {
                  href: 'https://bellocollective.com/how-to-make-a-podcast-website-that-doesnt-suck-f6fce111b4a1',
                  text: 'How to Make a Podcast Website That Doesn’t Suck',
                  description: '(Bello Collective)'
                },
                {
                  href: 'https://medium.com/podcast-101/what-every-podcast-website-should-include-34a342dfad94',
                  text: 'What Every Podcast Website Should Include',
                  description: '(Sonics Podcasts)'
                }
              ]}
            />
            <h2>Prepare for launch!</h2>
            <p>
              You’re making this great thing (yay!) and you want to make sure the world hears it. Or, at least, that <em>someone</em> hears it. How do
              you make sure people know when your podcast goes live?
            </p>
            <LinkList
              links={[
                {
                  href: 'https://www.weeditpodcasts.com/7-steps-for-a-successful-podcast-launch/',
                  text: '7 Steps for a Successful Podcast Launch',
                  description: '(We Edit Podcasts)'
                }
              ]}
            />
          </article>
        </LearningNav>
      </Page>
    );
  }
}
