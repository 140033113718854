import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class LinkList extends Component {
  render() {
    return <ul>{this.props.links.map(this._renderListItem)}</ul>;
  }

  _renderListItem = link => (
    <li key={link.text}>
      {this._renderLink(link)}
      {link.description ? <div dangerouslySetInnerHTML={{ __html: link.description }}/> : link.children && <div>{link.children}</div>}
    </li>
  );

  _renderLink(link) {
    return link.to ? <Link to={link.to}>{link.text}</Link> : <a href={link.href}>{link.text}</a>;
  }
}
