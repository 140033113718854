import React, { Component } from 'react';
import './styles.scss';

export default class Checkmark extends Component {
  render() {
    return (
      <div className={`checkmark-component ${this.props.className || ''}`}>
        <div className="checkmark-shape">
          <div className="short-side" />
          <div className="long-side" />
        </div>
      </div>
    );
  }
}
