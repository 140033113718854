import get from 'lodash/get';
import qs from 'qs';
import { isProduction } from '../config';

const DRAPER_BASE_URL = isProduction ? 'https://draper.radiopublic.com' : 'https://draper-staging.radiopublic.com';

export default class Draper {
  static async getCanonicalFeedUrl(options: { programId?: string; url?: string }): Promise<string> {
    const feed = await this.getFeed(options);
    const selfLink = feed.rss[0].channel[0]['http://www.w3.org/2005/Atom:link'].find(link => link.$.rel === 'self');
    return selfLink.$.href;
  }

  /**
   * Returns the feed as an object returned from Draper's JSON endpoint.
   */
  static async getFeed(options: { programId?: string; url?: string }) {
    const program_id = get(options, 'programId');
    const url = get(options, 'url');
    if (!(program_id || url)) {
      throw new Error('A programId or url parameter is required.');
    }
    const response = await fetch(`${DRAPER_BASE_URL}/transform.json?${qs.stringify({ program_id, url, patient: -1 })}`);
    if (!response.ok) {
      throw new Error(`Draper returned an error status code: ${response.status}`);
    }
    const feed = await response.json();
    return feed;
  }
}
