import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rpFlagImage from '../../shared-assets/radiopublic-flag-red.svg';
import MobileMenu from './MobileMenu';
import './styles.scss';

interface PageHeaderProps {
  desktopNavLinks?: LinkData[]; //optional links to override defaults
  mobileNavLinks?: LinkData[];
  simplifiedHeader?: boolean;
}

export interface LinkData {
  text: string;
  url: string;
  renderAsButton?: boolean;
}

export default class PageHeaderV2 extends Component<PageHeaderProps> {
  state = { showMobileMenu: false };

  private defaultDesktopNavLinks: LinkData[] = [
    {
      text: 'Pricing',
      url: '/pricing'
    },
    {
      text: 'Learning guides',
      url: '/learn'
    },
    {
      text: 'Login',
      url: '/dashboard/login'
    },
    {
      text: 'Get started',
      url: '/signup',
      renderAsButton: true
    }
  ];

  private defaultMobileNavLinks: LinkData[] = [
    {
      text: 'Tools for Podcasters',
      url: '/tools'
    },
    {
      text: 'Learning Guides',
      url: '/learn'
    },
    {
      text: 'Dashboard',
      url: '/dashboard/login'
    }
  ];

  render() {
    return (
      <nav className="page-header">
        <div className={`page-header-container ${this.props.simplifiedHeader ? 'page-header-simplified' : ''}`}>
          <div className="rp-logo">
            <Link to="/">
              <img src={rpFlagImage} alt="RadioPublic" />
            </Link>
          </div>
          {this._renderUncollapsedHeaderLinks()}
          {this._renderMenuButton()}
        </div>
        {this.state.showMobileMenu && (
          <MobileMenu menuSections={this.props.mobileNavLinks || this.defaultMobileNavLinks} onDismiss={this._handleMobileMenuDismissal} />
        )}
      </nav>
    );
  }

  private _handleMobileMenuDismissal = () => this.setState({ showMobileMenu: false });

  private _handleMenuButtonClick = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  private _renderMenuButton() {
    if (!this.props.simplifiedHeader) {
      return <button onClick={this._handleMenuButtonClick}>MENU</button>;
    }
  }

  private _renderUncollapsedHeaderLinks() {
    if (!this.props.simplifiedHeader) {
      const links = this.props.desktopNavLinks || this.defaultDesktopNavLinks;
      return <div className="uncollapsed-header-links-group">{links.map(link => this._renderUncollapsedHeaderLink(link))}</div>;
    }
  }

  private _renderUncollapsedHeaderLink(link: LinkData) {
    return (
      <a className={`header-link ${link.renderAsButton ? 'button' : ''}`} href={link.url} key={link.url}>
        {link.text}
      </a>
    );
  }
}
