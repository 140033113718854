import React, { Component } from 'react';
import './styles.scss';

export default class EmailVerificationSentView extends Component {
  render() {
    return (
      <div className="podcast-verification-success-view">
        <h1>Check your inbox</h1>
        <p>We just emailed a confirmation link to the email {this.props.emailAddresses.length > 1 ? 'addresses' : 'address'} listed for your feed.</p>
        <p>Click the link to finish the signup process.</p>
      </div>
    );
  }
}
