import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import EmbedPlayer from '../EmbedPlayer';
import Draper from '../../services/Draper';
import isUrl from '../../utils/isUrl';
import rpPlaceholder from '../../shared-assets/rp-initials-flag-red.svg';
import ITunesService from '../../services/ITunesService';
import './styles.scss';

const SEARCH_AUTOCOMPLETE_ENDPOINT = 'https://search.radiopublic.com/autocomplete?query=';
const ITUNES_REGEX = /^https?:\/\/(?:itunes|podcasts)\.apple\.com/i;

interface SearchResult {
  programId: string;
  title: string;
  imageUrl: string;
}

interface SearchableEmbedPlayerProps {
  initialFeedUrl: string;
}
export default function SearchableEmbedPlayer(props: SearchableEmbedPlayerProps) {
  // State and setters for ...
  // Search term
  const [searchTerm, setSearchTerm] = useState('');
  // API search results
  const [results, setResults] = useState<SearchResult[]>([]);
  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [initialFeedUsed, setinitialFeedUsed] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState('');

  // Effect for API call
  useEffect(() => {
    const deeplink = props.initialFeedUrl && !initialFeedUsed;
    if (deeplink) {
      setSearchTerm(props.initialFeedUrl);
    }

    if (debouncedSearchTerm) {
      setIsSearching(true);
      searchFeeds(debouncedSearchTerm).then(results => {
        setIsSearching(false);
        setResults(results);

        if (deeplink) {
          setinitialFeedUsed(true);
          if (results.length > 0) {
            setSelectedProgramId(results[0].programId);
          }
        }
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm, props.initialFeedUrl]); // call effect if debounced search term changes OR prop change

  return (
    <div className="search-embed-player" key={props.initialFeedUrl}>
      <input
        type="search"
        placeholder="Search for your show, or add your RSS feed / iTunes link"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <div className="search-results-container">
        {isSearching && results.length == 0 && (
          <div className="search-message">
            <h5>Searching...</h5>
          </div>
        )}
        {!isSearching && results.length == 0 && (
          <div className="search-message">
            <h5>No matches found</h5>
          </div>
        )}
        {results.map(result => (
          <div
            className="search-result"
            key={result.programId}
            onClick={e => {
              setSelectedProgramId(result.programId);
            }}
          >
            <img
              src={result.imageUrl ? `https://spotlight.radiopublic.com/images/thumbnail?url=${encodeURIComponent(result.imageUrl)}` : rpPlaceholder}
            />
            <h5>{result.title}</h5>
          </div>
        ))}
      </div>
      <div className="embed-container">
        <EmbedPlayer programId={selectedProgramId} />
      </div>
    </div>
  );
}

function searchFeeds(query) {
  const term = _trim(query);
  if (term !== '') {
    if (isUrl(term)) {
      if (ITUNES_REGEX.test(term)) {
        return ITunesService.getFeedUrlFromITunesLink(term).then(feedUrl => {
          if (feedUrl) {
            return _showResultForUrl(feedUrl);
          } else {
            return Promise.resolve([]);
          }
        });
      } else {
        return _showResultForUrl(term);
      }
    } else {
      return _performQuery(term);
    }
  }

  return Promise.resolve([]);
}

async function _showResultForUrl(url: string) {
  try {
    return Draper.getFeed({ url: url }).then(json => [
      {
        programId: get(json, 'rss[0].channel[0]["https://www.w3id.org/rp/v1:slug"][0].$$'),
        title: get(json, 'rss[0].channel[0].title'),
        imageUrl: get(json, 'rss[0].channel[0]["https://www.w3id.org/rp/v1:image"][0].$.href')
      }
    ]);
  } catch (error) {
    console.error('An error occurred while fetching the URL: ' + error.stack); // eslint-disable-line no-console
    return Promise.resolve([]);
  }
}

async function _performQuery(term: string) {
  return fetch(`${SEARCH_AUTOCOMPLETE_ENDPOINT}${encodeURIComponent(term)}`)
    .then(r => r.json())
    .then(json => _uniq(json));
}

function _trim(str?: string) {
  return (str || '').replace(/(^\s+)|(\s+$)/g, '');
}

function _uniq(array, prop = 'programId') {
  const matchingProps = array.map(item => item[prop]);
  return array.reduce((acc, val, index) => {
    return matchingProps.indexOf(val[prop]) === index ? acc.concat(val) : acc;
  }, []);
}

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}
