import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import LearningGuidePromo from '../../components/LearningGuidePromo';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import ToolsNav from '../../components/ToolsNav';
import EmbedImages from './EmbedImages';
import audioFileImage from './assets/audio-file.svg';
import backgroundSquigglyImage from './assets/background-squiggly.svg';
import calendarImage from './assets/calendar.svg';
import computerEditingImage from './assets/computer-editing.svg';
import fansImage from './assets/fans.svg';
import learningGuideImage from './assets/learning-guide.svg';
import phoneInHandImage from './assets/phone-in-hand.svg';
import sketchBackgroundImage from './assets/sketch-background.jpg';
import './styles.scss';

export default class EmbedPage extends Component {
  componentDidMount() {
    Analytics.pageview('/embed');
  }

  render() {
    return (
      <Page className="embed-page" title="Embed your podcast anywhere with RadioPublic" useV2Header={true}>
        <PanelWithBackdrop
          className="top-panel"
          backgroundImage={sketchBackgroundImage}
          arrowLinkHref="#intro"
          aspectRatio="2/1"
          justifyBackground="left"
        >
          <div>
            <div>
              <h1>Embed your podcast everywhere</h1>
              <p>Get a web player that helps your show grow with a free RadioPublic account</p>
            </div>
            <div>
              <EmbedImages />
            </div>
          </div>
          <Link className="jumbo-round-link" to="/signup">
            Get started
          </Link>
        </PanelWithBackdrop>
        <ToolsNav className="sections">
          <div>
            <img src={backgroundSquigglyImage} alt="" />
          </div>
          <section className="intro-section">
            <div>
              <h2>The perfect embed</h2>
              <p>
                RadioPublic’s free web player works for any podcast, regardless of hosting provider. It works for everyone, and it’s designed to
                convert a casual web visitor to a regular listener to your show on any device, anywhere in the world.
              </p>
              <h2 id="intro">See how it works</h2>
              <p>
                Here’s an example of the player that automatically updates with the latest episode from the show. Press play to try it out, then pause
                the audio to see how the embed player helps you grow audience.
              </p>
              <iframe
                title="rp-embed"
                sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups allow-forms"
                scrolling="no"
                width="100%"
                height="185"
                frameBorder="0"
                src="https://embed.radiopublic.com/e?if=songexploder"
              ></iframe>
            </div>
            <LearningGuidePromo
              className="learning-guide"
              linkHref="https://help.radiopublic.com/hc/en-us/articles/360000504994-Using-RadioPublic-s-free-embed-player"
              image={learningGuideImage}
              title="Put your podcast anywhere"
            >
              Our podcast player works on any website, and it’s optimized for use on publishing platforms like WordPress, Medium, and Reddit.
            </LearningGuidePromo>
          </section>
          <section className="reversed">
            <div>
              <h2>Automatically updates</h2>
              <p>Add the player once and it updates automatically whenever you publish new episodes.</p>
            </div>
            <div>
              <img src={calendarImage} alt="calendar" />
            </div>
          </section>
          <section>
            <div className="embed-image">
              <EmbedImages />
            </div>
            <div>
              <h2>Embed any episode</h2>
              <p>Select any episode from your show–your trailer, the start of a new series, or just a really awesome one–to put on any web page.</p>
            </div>
          </section>
          <section className="reversed">
            <div>
              <h2>Optimized for mobile</h2>
              <p>
                A player that looks great on any sized screen, on any device. Tap the + sign on the embed player on any web page to open it in the
                RadioPublic app.
              </p>
            </div>
            <div>
              <img src={phoneInHandImage} alt="A phone in a hand" />
            </div>
          </section>
          <section>
            <div>
              <img src={fansImage} alt="Fanatic sports fans watching a game." />
            </div>
            <div>
              <h2>Convert listeners into fans</h2>
              <p>
                A casual visitor sampling your show on the web is encouraged to keep listening later at just the right moment: when they hit pause.
              </p>
            </div>
          </section>
          <section className="reversed">
            <div>
              <h2>Easy access to download the file</h2>
              <p>Fans can download the audio file right from the player with ease.</p>
            </div>
            <div>
              <img src={audioFileImage} alt="An audio file waveform on a phone" />
            </div>
          </section>
          <section>
            <div>
              <img src={computerEditingImage} alt="A lady editing something on a huge computer." />
            </div>
            <div>
              <h2>Press for the embed code</h2>
              <p>
                The easiest way to get the embed code for journalists (or your fans) to share your show: just grab and go right from your embed
                player.
              </p>
            </div>
          </section>
        </ToolsNav>
      </Page>
    );
  }
}
