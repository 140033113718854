import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from './components/AsyncComponent';
import AppPage from './containers/AppPage';
import EmbedPage from './containers/EmbedPage';
import GrowPage from './containers/GrowPage';
import HomePage from './containers/HomePageV2';
import ImprovePage from './containers/ImprovePage';
import LearningPage from './containers/LearningPage';
import Login from './containers/Login';
import MarketingPage from './containers/MarketingPage';
import PricingPage from './containers/PricingPage';
import RevenuePage from './containers/RevenuePage';
import SignupPage from './containers/SignupPage';
import StartPage from './containers/StartPage';
import WebsiteSurveyPage from './containers/WebsiteSurveyPage';
import ToolsPage from './containers/ToolsPage';
import RouterContext from './utils/RouterContext';
import PodcasterToastPromptBanner from './components/PodcasterToastPromptBanner';
import PodsiteTrialPromo from './components/PodsiteTrialPromo';

const AsyncDashboard = asyncComponent(() => import('./containers/PodsiteDashboardPage'));
const AsyncEmailOptOutPage = asyncComponent(() => import('./containers/EmailOptOutPage'));

class App extends Component {
  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <PodcasterToastPromptBanner />
        <Switch>
          <Route exact path="/app" component={AppPage} />
          <Route exact path="/embed" component={EmbedPage} />
          <Route exact path="/grow" component={GrowPage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/improve" component={ImprovePage} />
          <Route exact path="/learn" component={LearningPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/marketing" component={MarketingPage} />
          <Route exact path="/revenue" component={RevenuePage} />
          <Route exact path="/signup" component={SignupPage} />
          <Route exact path="/start" component={StartPage} />
          <Route exact path="/website-survey" component={WebsiteSurveyPage} />
          <Route exact path="/tools" component={ToolsPage} />
          <Route exact path="/email-opt-out" component={AsyncEmailOptOutPage} />
          <Route exact path="/([pmPM]{2}\d{2})" component={PodsiteTrialPromo} />
          <Route exact path="/pricing" component={PricingPage} />

          {/* 👇 Dashboard pages 👇 */}
          {<Route exact path="/dashboard" component={AsyncDashboard} />}
          {<Route exact path="/dashboard/podsite-trial" component={AsyncDashboard} />}
          {<Route exact path="/dashboard/*" component={AsyncDashboard} />}
        </Switch>
        <RouterContext />
      </div>
    );
  }
}

export default App;
