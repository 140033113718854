import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import LearningNav from '../../components/LearningNav';
import LinkList from '../../components/LinkList';
import Page from '../../components/Page';
import PanelWithBackdrop from '../../components/PanelWithBackdrop';
import crowdBackgroundImage from './assets/crowd-background.jpg';

export default class GrowPage extends Component {
  componentDidMount() {
    Analytics.pageview('/grow');
  }

  render() {
    return (
      <Page
        className="learning-page-base"
        title="Everything you need to know about marketing your podcast from your friends at RadioPublic"
        useV2Header={true}
      >
        <PanelWithBackdrop className="top-panel-base" backgroundImage={crowdBackgroundImage} arrowLinkHref="#intro" aspectRatio="2/1">
          <h1>Growing your podcast</h1>
          <p>Everything you need to know about marketing your podcast</p>
        </PanelWithBackdrop>
        <LearningNav className="sections">
          <article>
            <p id="intro">
              We have a secret: everything you want to know about growing your audience comes down to one thing -{' '}
              <strong>how you market your podcast to listeners</strong>. That’s a combination of a few fundamental understandings: knowing who your
              podcast is for, what your audience needs to know that will compel them to listen, and where and how you can get your show to them. Yes,
              this takes some strategy and some investment, of both time and money. Don’t worry, we’re going to break it down so you&mdash;yes
              you!&mdash;can become a podcast marketing pro. (Ready to start that journey right now? Sign up for{' '}
              <Link to="/marketing">our free 21-day podcast marketing course</Link> delivered daily to your inbox.)
            </p>
            <p>
              A vital part of digital marketing is this trifecta: owned, earned, and paid media. Together, they’re the tripod that bolsters your show
              to stand on its own and grow.
            </p>
            <p>What are the differences between these three approaches, and how do you begin to plan for them?</p>
            <h2>Owned media</h2>
            <p>
              <strong>Owned media</strong> is anything you have control over, which includes any and everything in your podcast’s RSS feed (including
              the audio, show notes, and other metadata), your website, your social media accounts, your email list, and any creative campaigns you
              might create yourself. Flyers at a coffee shop or the library around town? Getting up at a Q&A at an event where you lead off your
              question by stating your name and affiliation? Those are all marketing opportunities within your grasp.
            </p>
            <p>Where can you ace your owned media?</p>
            <h3>Your website</h3>
            <LinkList
              links={[
                {
                  href: 'https://about.radiopublic.com/category/for-podcasters/podsites-podcast-websites/how-to-make-a-great-podcast-website/',
                  text: 'How to make a great podcast website',
                  description: '(RadioPublic)'
                },
                {
                  text: 'What Every Podcast Website Should Include',
                  description: '(Sonics Podcasts)',
                  href: 'https://medium.com/podcast-101/what-every-podcast-website-should-include-34a342dfad94'
                },
                {
                  text: 'How to Make a Podcast Website That Doesn’t Suck',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/how-to-make-a-podcast-website-that-doesnt-suck-f6fce111b4a1'
                },
                {
                  text: 'How to build a website for your podcast',
                  description: '(Pacific Content)',
                  href: 'https://blog.pacific-content.com/how-to-build-a-website-for-your-podcast-e9b59efcf395'
                },
                {
                  text: 'The 7 Best Podcast Promotion Strategies (And How to Use Them)',
                  description: '(Patreon)',
                  href: 'https://blog.patreon.com/promote-podcast'
                }
              ]}
            />
            <h3>Social media</h3>
            <LinkList
              links={[
                {
                  text: 'How Radio Producers Can Make the Most of Social Media',
                  description: '(Transom)',
                  href: 'https://transom.org/2013/radio-producers-use-social-media/'
                },
                {
                  text: 'Promote Your Podcast with Instagram',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/promotion/promote-your-podcast-with-instagram/'
                },
                {
                  text: 'How to Promote Your Podcast on Twitter',
                  description: '(The Podcast Dude)',
                  href: 'https://seanwes.com/podcastdude/068-how-to-promote-your-podcast-on-twitter/'
                },
                {
                  text: 'Monetization, Community Building & Social Media',
                  description: '(Multitude Productions)',
                  href: 'https://www.multitude.productions/blog/monetization-community-building'
                },
                {
                  text: '7 Strategies for Building a Strong Podcast Community',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/7-strategies-for-building-a-strong-podcast-community/'
                },
                {
                  text: '12 Ways To Promote Your Podcast Within Your Existing Marketing Channels',
                  description: '(Huffington Post)',
                  href: 'https://www.huffingtonpost.com/james-carbary/12-ways-to-promote-your-p_b_13414070.html'
                },
                {
                  text: 'Gaining Listeners by Marketing Your Podcast',
                  description: '(RadioPublic)',
                  href:
                    'https://about.radiopublic.com/2019/02/01/gaining-listeners-by-marketing-your-podcast-a-key-ingredient-in-radiotopia-and-radiopublics-recipe-for-success/'
                },
                {
                  text: 'Sonar Podcast Marketing™: The Solution for Digital Media Marketing and Audience Growth',
                  description: '(RadioPublic)',
                  href:
                    'https://about.radiopublic.com/2019/02/01/sonar-podcast-marketing-the-solution-for-digital-media-marketing-and-audience-growth/'
                }
              ]}
            />
            <h2>Earned media</h2>
            <p>
              <strong>Earned media</strong> is any kind of marketing someone does on your behalf because they’re into what you’re doing, and it comes
              from doing what you’re doing well, and in a way that it resonates with people enough that they <em>have</em> to share it. This includes
              your loyal fans, your guests, and the press voluntarily sharing your show.
            </p>
            <p>What can you do to enable earned media?</p>
            <h3>Ensure that your podcasts are sharable</h3>
            <LinkList
              links={[
                {
                  text: 'Share an episode *exactly* where you want to start listening',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/08/31/share-an-episode-exactly-where-you-want-to-start-listening/'
                },
                {
                  text: 'A guide to creating a "how to listen" guide',
                  description: '(RadioPublic)',
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360009011514-A-guide-to-creating-a-how-to-listen-guide'
                },
                {
                  text: 'Using RadioPublic’s free embed player',
                  description: '(RadioPublic)',
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360000504994-Using-RadioPublic-s-free-embed-player'
                }
              ]}
            />
            <h3>Community building and engagement</h3>
            <LinkList
              links={[
                {
                  text: 'Building Your Podcast’s Community',
                  description: '(Amanda McLoughlin)',
                  href: 'https://medium.com/@shessomickey/building-your-podcasts-community-414e599c1409'
                },
                {
                  text: '7 Strategies for Building a Strong Podcast Community',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/7-strategies-for-building-a-strong-podcast-community/'
                },
                {
                  text: 'Attract More Listeners to Your Podcast Without Spending Tons of Money',
                  description: '(Podcast Movement)',
                  href: 'https://podcastmovement.com/news/attract-more-listeners-to-your-podcast-without-spending-tons-of-money/'
                },
                {
                  text: '11 Steps For Attracting New Podcast Listeners',
                  description: '(We Edit Podcasts)',
                  href: 'https://www.weeditpodcasts.com/11-steps-for-attracting-new-podcast-listeners/'
                },
                {
                  text: 'Beyond the Headphones: How to Build a Community Around Your Podcast',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/beyond-the-headphones-d98b5efce1fb'
                },
                {
                  text: 'How to Grow Your Podcast: Dealing with Post-Launch Stagnation & Frustration',
                  description: '(The Podcast Host)',
                  href: 'https://www.thepodcasthost.com/promotion/how-do-i-grow-my-podcast/'
                }
              ]}
            />
            <h3>Create a podcast press kit</h3>
            <LinkList
              links={[
                {
                  text: 'Press Play on a Podcast Press Kit',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/press-play-on-a-podcast-press-kit-c729befb15fd'
                },
                {
                  text: 'Assemble a podcast press kit',
                  description: '(RadioPublic)',
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360000492733-Assemble-a-podcast-press-kit'
                },
                {
                  text: 'How to pitch your podcast',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/06/19/how-to-pitch-your-podcast/'
                },
                {
                  text: 'Marketing your Podcast',
                  description: '(HowToMakeAPodcast.org)',
                  href: 'https://www.howtomakeapodcast.org/chapters/#/chapter-7/'
                },
                {
                  text: 'A Guide to Unleashing Your Podcast’s Press Release',
                  description: '(Elena Fernandez Collins)',
                  href: 'https://elenafernandezcollins.com/2019/06/19/how-to-write-podcast-press-release/'
                }
              ]}
            />
            <h3>Run a live event</h3>
            <LinkList
              links={[
                {
                  text: 'We’ll Do It Live: A DIY Guide to Podcast Live Shows',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/well-do-it-live-248d07957cd4'
                },
                {
                  text: 'Audio Danger: Going Live',
                  description: '(Nieman Storyboard)',
                  href: 'https://niemanstoryboard.org/stories/audio-danger-the-lessons-of-going-live/'
                }
              ]}
            />
            <h3>Podcast guests (both on your podcast and on other people’s podcasts)</h3>
            <LinkList
              links={[
                {
                  text: 'Your guests are an opportunity for audience growth',
                  description: '(RadioPublic)',
                  href: 'https://about.radiopublic.com/2018/09/05/your-guests-are-an-opportunity-for-audience-growth-part-1/'
                }
              ]}
            />
            <h2>Paid media</h2>
            <p>
              <strong>Paid media</strong> is exactly as it sounds: you spend money on marketing your podcast somewhere else. This includes social
              media marketing, audio or web ads, and podcast cross promotion.
            </p>
            <p>What can you do to prepare for paid media?</p>
            <h3>Distribute your podcast press kit</h3>
            <LinkList
              links={[
                {
                  text: 'Press Play on a Podcast Press Kit',
                  description: '(Bello Collective)',
                  href: 'https://bellocollective.com/press-play-on-a-podcast-press-kit-c729befb15fd'
                },
                {
                  text: 'Assemble a podcast press kit',
                  description: '(RadioPublic)',
                  href: 'https://help.radiopublic.com/hc/en-us/articles/360000492733-Assemble-a-podcast-press-kit'
                }
              ]}
            />
            <h3>Cross promotion on other podcasts</h3>
            <LinkList
              links={[
                {
                  text: 'How to Effectively Cross-Promote Your Podcast',
                  description: '(Discover Pods)',
                  href: 'https://discoverpods.com/how-to-cross-promote-podcast/'
                },
                {
                  text: 'Podcast cross-pollination: 6 lessons from a hacker podcast episode swap',
                  description: '(Pacific Content)',
                  href: 'https://blog.pacific-content.com/podcast-cross-pollination-6-lessons-from-a-hacker-podcast-episode-swap-27b33af85376'
                }
              ]}
            />
            <h3>Buying ads for your show or sponsoring another shows</h3>
            <LinkList
              links={[
                {
                  text: 'If You Had $100,000 to Market Your Podcast, How Would You Spend It?',
                  description: '(Jacobs Media)',
                  href: 'https://jacobsmedia.com/100000-market-podcast-spend/'
                },
                {
                  text: 'Podcast Advertising: $51,975 Spent. Here’s What We Learned',
                  description: '(Ahrefs)',
                  href: 'https://ahrefs.com/blog/podcast-advertising/'
                }
              ]}
            />
          </article>
        </LearningNav>
      </Page>
    );
  }
}
