import React from 'react';
import HashLink from '../HashLink';
import downArrow from './assets/down-arrow.svg';
import backgroundImage from './assets/panel-backdrop.jpg';
import './styles.scss';

const PanelWithBackdrop = props => {
  return (
    <div className="panel-with-backdrop">
      {props.overlayColor && <div className="backdrop-overlay" style={{ backgroundColor: props.overlayColor }} />}
      <div
        className={`panel-backdrop ${props.aspectRatio === '2/1' ? 'aspect-2-1' : 'aspect-3-2'}`}
        style={{ justifyContent: props.justifyBackground === 'right' ? 'flex-end' : props.justifyBackground === 'left' ? 'flex-start' : 'center' }}
      >
        <img src={props.backgroundImage || backgroundImage} alt="podcast recording studio" className={props.imageClassName} />
      </div>
      <div className="panel-content">
        <div className={props.className || ''}>{props.children}</div>
      </div>
      {renderArrowLink(props)}
    </div>
  );
};

function renderArrowLink(props) {
  if (props.arrowLinkHref) {
    return (
      <HashLink className="arrow-link" to={props.arrowLinkHref} behavior="smooth">
        <img src={downArrow} alt="down arrow" />
      </HashLink>
    );
  }
}

export default PanelWithBackdrop;
