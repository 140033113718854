import React from 'react';
import LinksNav from './LinksNav';

const links = [
  {
    text: 'Embed',
    href: '/embed'
  },
  {
    text: 'App',
    href: '/app'
  },
  {
    text: 'Podfund',
    href: 'https://pod.fund/'
  },
  {
    text: 'Marketing Guide',
    href: '/marketing'
  }
];

const ToolsNav = props => <LinksNav links={links} {...props} />;
export default ToolsNav;
