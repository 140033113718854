import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

interface CTAProps {
  className?: string;
  title: string;
  textLines: string[];
  links: { text: string; path: string }[];
}

export default function CallToActionSectionMultiline(props: CTAProps) {
  return (
    <div className={`call-to-action-section ${props.className || ''}`}>
      <h1>{props.title}</h1>
      <div className="call-to-action-text">
        {props.textLines.map(line => (
          <p>{line}</p>
        ))}
      </div>
      <div className="call-to-action-links">
        {props.links.map(link => (
          <Link className="call-to-action-button" to={link.path}>
            {link.text}
          </Link>
        ))}
      </div>
    </div>
  );
}
