import get from 'lodash/get';
import merge from 'lodash/merge';
import { UnauthorizedError } from '../models/errors';
import assertOk from '../utils/assertOk';
import { getHistory } from '../utils/RouterContext';

const KEY_AUTH_TOKEN = 'rp-auth-token';
const KEY_PROGRAM_ID = 'rp-program-id';

/**
 * This class handles sending authenticated service requests and redirecting
 * the user to /login if the backend indicates that they're not logged in.
 */
export default class PodcasterAuthorizer {
  static _cachedProgramId: null | string = null;
  static _cachedToken: null | string = null;

  static async fetch(url: string, options?: any) {
    if (!this._authToken) {
      getHistory().replace('/login');
      throw new UnauthorizedError('The auth token has not been set.');
    }
    const optionsWithAuthorization = merge({}, options, {
      headers: { Authorization: `Bearer ${this._authToken}` }
    });
    const response = await fetch(url, optionsWithAuthorization);
    if (response.status === 401) {
      getHistory().replace('/login');
      throw new UnauthorizedError('The auth token has expired.');
    }
    return response;
  }

  static async fetchAndAssertOk(url: string, options?: any) {
    const response = await this.fetch(url, options);
    return assertOk(response);
  }

  static get programId(): string | null {
    if (this._cachedProgramId) {
      return this._cachedProgramId;
    }
    if (typeof get(window, 'localStorage.getItem') === 'function') {
      // When react-snapshot runs this code, window.localStorage.getItem() doesn't exist.
      return window.localStorage.getItem(KEY_PROGRAM_ID) || null;
    }
    return null;
  }

  static setProgramId(programId) {
    this._cachedProgramId = programId;
    window.localStorage.setItem(KEY_PROGRAM_ID, programId);
  }

  static setToken(authToken) {
    this._cachedToken = authToken;
    window.localStorage.setItem(KEY_AUTH_TOKEN, authToken);
  }

  static get _authToken(): string | null {
    if (this._cachedToken) {
      return this._cachedToken;
    }
    if (typeof get(window, 'localStorage.getItem') === 'function') {
      // When react-snapshot runs this code, window.localStorage.getItem() doesn't exist.
      return window.localStorage.getItem(KEY_AUTH_TOKEN) || null;
    }
    return null;
  }
}
