import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from 'react-ga';
import { isUri } from 'valid-url';
import debounce from 'lodash/debounce';
import SearchService, { AutocompleteSuggestion } from '../../../services/SearchService';
import './styles.scss';

const PROBABLY_A_URL_REGEX = /(^http)|(www\.)|(\.com)|(\.fm)|(\.net)|(\.org)/;

interface VerificationViewProps {
  onSubmit: (string) => void;
  prepopulatedUrl?: string;
}

export default class PodcastVerificationView extends Component<VerificationViewProps> {
  state = {
    inputText: '',
    inputFocused: false,
    urlWithScheme: '',
    isValid: false,
    searchResults: null as AutocompleteSuggestion[] | null
  };

  private _currentSearchId = 0;

  componentDidMount() {
    if (this.props.prepopulatedUrl) {
      this._updateStateWithUrl(this.props.prepopulatedUrl);
    }

    Analytics.event({
      category: 'Signup',
      action: 'Verification prompt displayed'
    });
  }

  render() {
    return (
      <div className="validate-podcast-view">
        <div className="signup-page-step-number">Step 1</div>
        <h1>Verify your podcast</h1>
        <div className="validate-podcast-description">
          You can search for your show by title or paste your show’s RSS feed, RadioPublic, or iTunes link. We need this link to help prove you have
          ownership of the podcast and add it to the search directory.
        </div>
        <div className="validate-podcast-input-container">
          <input
            type="text"
            placeholder="Search by title or paste a URL"
            value={this.state.inputText}
            onFocus={this._handleInputFocus}
            onBlur={this._handleInputBlur}
            onChange={this._handleInputTextChange}
          />
          {this._renderSearchResults()}
        </div>
        <button disabled={!this.state.isValid} onClick={this._handleButtonClick}>
          Link podcast
        </button>
        <Link className="validate-login-link" to="/login">
          Log in to your podcast
        </Link>
        <p className="validate-explore-link">
          Not a creator, just looking to listen? <a href="https://www.radiopublic.com/explore"> Explore podcasts on RadioPublic</a>
        </p>
      </div>
    );
  }

  private _checkUrlValidity(url) {
    // Also check that a TLD is included (or it's an IP address), since `isUri` doesn't enforce that.
    if (!(isUri(url) && url.indexOf('.') !== -1)) {
      return false;
    }
    // The last thing to verify is that the TLD is at last two characters. IP addresses also work.
    const segments = url.split('.');
    const lastSegment = segments[segments.length - 1];
    const topLevelDomain = lastSegment.split('/')[0];
    return topLevelDomain.length > 1;
  }

  private _handleButtonClick = () => {
    this.props.onSubmit(this.state.urlWithScheme);
  };

  private _handleInputBlur = () => {
    // Delay the blur so that if the blur occurs because a search
    // result is about to be clicked, it continues to be shown for enough time.
    setTimeout(() => this.setState({ inputFocused: false }), 100);
  };

  private _handleInputFocus = () => this.setState({ inputFocused: true });

  private _handleInputTextChange = event => {
    const text = event.target.value;
    const probablyAUrl = !!PROBABLY_A_URL_REGEX.exec(text);
    if (probablyAUrl) {
      this._updateStateWithUrl(text);
    } else {
      this.setState({ inputText: text });
      this._updateStateWithSearchQuery(text);
    }
  };

  private _handleSearchResultClick = event => this.props.onSubmit(event.target.dataset.url);

  private _renderSearchResults() {
    if (!(this.state.inputText && this.state.inputFocused && this.state.searchResults)) {
      return;
    }

    if (this.state.searchResults.length) {
      return <ul className="remove-list-styles search-results">{this.state.searchResults.map(this._renderSearchResult)}</ul>;
    }

    return (
      <div className="no-results-message">
        <div>
          <h4>No search results found.</h4>
          <p>Good news—you can paste your feed URL instead.</p>
        </div>
      </div>
    );
  }

  private _renderSearchResult = (result: AutocompleteSuggestion) => (
    <li key={result.url} data-url={result.url} onClick={this._handleSearchResultClick}>
      <div>
        <img src={result.imageUrl} alt="" />
        <span>{result.title}</span>
      </div>
    </li>
  );

  /**
   * This method is debounced in order to prevent sending too many
   * fetch requests while the user is typing, because that can cause
   * the UI to freeze.
   */
  private _updateStateWithSearchQuery = debounce(async query => {
    if (!query) {
      this.setState({ searchResults: [] });
      return;
    }
    const searchId = ++this._currentSearchId;
    const searchResults = await SearchService.getAutocompleteSuggestions({ query });
    if (searchId < this._currentSearchId) {
      // A more recent search was started before receiving this response.
      return;
    }
    this.setState({ searchResults });
  }, 250);

  private _updateStateWithUrl(url) {
    const urlIncludesScheme = url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
    const urlWithScheme = urlIncludesScheme ? url : `http://${url}`;
    // Only enable the submit button if the URL is valid.
    const isValid = this._checkUrlValidity(urlWithScheme);
    this.setState({
      inputText: url,
      urlWithScheme,
      isValid,
      searchResults: null
    });
  }
}
