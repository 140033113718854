import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import whiteTriangleImage from './assets/white-triangle.svg';
import './styles.scss';

export default class Promo extends Component {
  render() {
    return (
      <div className={`promo-component ${this.props.className || ''}`}>
        <div>
          <div>
            <h2 id="lg-h2">{this.props.header}</h2>
            <span>{this.props.title}</span>
          </div>
          <div>
            <img src={this.props.image} alt="" />
          </div>
        </div>
        <p id="lg-p">{this.props.children}</p>
        {this._renderLink()}
      </div>
    );
  }

  _renderLink() {
    if (this.props.linkTo) {
      return (
        <Link to={this.props.linkTo}>
          {this.props.linkText}
          <img src={whiteTriangleImage} alt="" />
        </Link>
      );
    }
    return (
      <a href={this.props.linkHref}>
        {this.props.linkText}
        <img src={whiteTriangleImage} alt="" />
      </a>
    );
  }
}
