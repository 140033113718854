import get from 'lodash/get';
import { SidebarLinkSection } from './SidebarNav';
import { Tab } from '.';

export const isSectionValid = (error: any, section: SidebarLinkSection): boolean => {
  const tabsWithPaths = section.tabs.filter(tab => tab.errorKeys);
  for (const tab of tabsWithPaths) {
    if (!isTabValid(error, tab)) {
      return false;
    }
  }
  return true;
};

export const isTabValid = (error: any, tab: Tab): boolean => {
  if (!tab.errorKeys) {
    return true;
  }
  for (const path of tab.errorKeys) {
    if (get(error, path)) {
      return false;
    }
  }
  return true;
};

export const errorMessageForKeyPath = (error: any, keyPath: string) => {
  return get(error, keyPath);
};
