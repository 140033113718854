import React, { Component } from 'react';
import Analytics from 'react-ga';
import * as typeformEmbed from '@typeform/embed';

const TYPEFORM_URL = 'https://radiopublic.typeform.com/to/hZACRt';

export default class WebsiteSurveyPage extends Component {
  componentDidMount() {
    Analytics.pageview('/website/survey');
  }

  render() {
    return <div style={{ width: '100%', height: '100vh' }} ref={this._setupTypeform} />;
  }

  _handleSurveyFinished = () => this.props.history.push('/website?finished=1');

  _setupTypeform = element => {
    try {
      typeformEmbed.makeWidget(element, TYPEFORM_URL, {
        onSubmit: this._handleSurveyFinished
      });
    } catch (error) {
      // This happens for some reason if the user presses the backbutton while on the Typeform embed.
      console.log(`An error occurred while setting up typeform.: ${error.stack}`);
    }
  };
}
