export interface AutocompleteSuggestion {
  title: string;
  url: string;
  imageUrl: string;
}

export default class SearchService {
  static async getAutocompleteSuggestions(options: { query: string }): Promise<AutocompleteSuggestion[]> {
    try {
      const response = await fetch(`https://search.radiopublic.com/autocomplete?query=${encodeURIComponent(options.query)}`);
      if (response.ok) {
        return await response.json();
      }
      const body = response.text && (await response.text());
      console.error(`The autocomplete endpoint returned an error response. Status code: ${response.status}, body: ${body}`);
    } catch (error) {
      console.error('An error occurred while fetching autocomplete suggestions: ' + error.stack);
    }
    return [];
  }
}
