import React, { Component } from 'react';
import Analytics from 'react-ga';
import errorGraphic from '../../shared-assets/error-graphic.svg';
import './styles.scss';

interface ErrorViewProps {
  className?: string;
  title?: string;
  message?: string;
  details?: string;
  reportedError?: string;
  hideButton?: boolean;
  onButtonClick?: () => void;
  buttonText?: string;
}

export default class ErrorView extends Component<ErrorViewProps> {
  componentDidMount() {
    const errorPayload = this.props.reportedError ? this.props.reportedError : this.props.details || this.props.message || this.props.title;
    Analytics.event({
      category: 'Error',
      action: 'Invalid input',
      label: errorPayload
    });
  }

  render() {
    return (
      <div className={`error-view-component ${this.props.className || ''}`}>
        <img src={errorGraphic} alt="There was an error" />
        <h1>{this.props.title || 'Oops, something’s wrong'}</h1>
        <p>{this.props.message || `Sorry, but an error occurred. Please try again later or contact us at help@radiopublic.com.`}</p>
        {this.props.details && <div className="error-details">{this.props.details}</div>}
        {!this.props.hideButton && <button onClick={this.props.onButtonClick}>{this.props.buttonText || 'Ok'}</button>}
      </div>
    );
  }
}
