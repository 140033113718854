import React, { Component } from 'react';
import Analytics from 'react-ga';
import ReactMarkdown from 'react-markdown';
import paidListensTermsOfService from '../../../shared-assets/paid-listens-terms-of-service';
import regularTermsOfService from './assets/podcaster-program-terms';
import './styles.scss';

interface TermsOfServiceProps {
  optedIntoPaidListens: boolean;
  onContinue: () => void;
}

export default class TermsOfServiceView extends Component<TermsOfServiceProps> {
  state = {
    termsAccepted: false
  };

  componentDidMount() {
    Analytics.event({ category: 'Signup', action: 'Review Terms of Service', nonInteraction: true });
  }

  render() {
    return (
      <div className="signup-terms-of-service-view">
        <div className="signup-page-step-number">Step 3</div>
        <h1>Review Terms of Service</h1>
        <div className="terms-of-service-content">
          <ReactMarkdown className="terms-of-service" source={this.props.optedIntoPaidListens ? paidListensTermsOfService : regularTermsOfService} />
          <div className="terms-of-service-controls">
            <div className="terms-checkbox">
              <input type="checkbox" checked={this.state.termsAccepted} onChange={this._handleTermsCheckboxChange} /> <b>I agree</b>
            </div>
            <div>
              <button className="theme-button" disabled={!this.state.termsAccepted} onClick={this.props.onContinue}>
                {this.props.optedIntoPaidListens ? 'Opt-In to Paid Listens' : 'Continue'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _handleTermsCheckboxChange = event => this.setState({ termsAccepted: event.target.checked });
}
