import React, { Component } from 'react';
import Analytics from 'react-ga';
import { Link } from 'react-router-dom';
import './styles.scss';

export default class TopBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { shown: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ shown: true });
      Analytics.event({ category: 'Top Banner', action: 'Show', label: this.props.analyticsLabel, nonInteraction: true });
    }, 1);
  }

  render() {
    return (
      <div className={`top-banner ${this.state.shown ? 'shown' : ''}`}>
        <div>
          <h2>{this.props.title}</h2>
          <Link to={this.props.buttonDestination} onClick={this._handleClick}>
            {this.props.buttonText}
          </Link>
          <button onClick={this._handleDismiss}>×</button>
        </div>
      </div>
    );
  }

  _handleClick = () => Analytics.event({ category: 'Top Banner', action: 'Button Clicked', label: this.props.analyticsLabel });

  _handleDismiss = () => {
    this.props.onDismiss();
    this.setState({ shown: false });
    Analytics.event({ category: 'Top Banner', action: 'Dismiss', label: this.props.analyticsLabel, nonInteraction: true });
  };
}
