import React, { Component } from 'react';
import Analytics from 'react-ga';
import newsletterImage from './assets/newsletter.svg';
import './styles.scss';

interface MailingListSignupProps {
  onContinue: (boolean) => void;
}

export default class MailingListSignupView extends Component<MailingListSignupProps> {
  state = {
    optedIntoMailingList: false
  };

  componentDidMount() {
    Analytics.event({ category: 'Signup', action: 'Mailing list step', nonInteraction: true });
  }

  render() {
    return (
      <div className="mailing-list-signup">
        <h1>Podcast marketing made simple</h1>
        <div className="image">
          <img src={newsletterImage} alt="Person next to a larger-than-life newsletter" />
        </div>
        <p>
          We think your podcast is great, and we'd love more people to know about it! Our free 21 day marketing course drops into your inbox daily
          with tips and advice on how to organically grow your audience.
        </p>
        <div className="checkbox">
          <input type="checkbox" checked={this.state.optedIntoMailingList} onChange={this._handleCheckboxChange} />
          <label>Yes, I would love to have more people learn about my show</label>
        </div>
        <button className="theme-button" onClick={this._handleButtonClick}>
          Complete signup
        </button>
      </div>
    );
  }

  private _handleButtonClick = () => this.props.onContinue(this.state.optedIntoMailingList);

  private _handleCheckboxChange = event => this.setState({ optedIntoMailingList: event.target.checked });
}
