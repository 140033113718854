export class APIError extends Error {
  body: any;
  constructor(message, body) {
    super(message);
    this.message = message;
    this.body = body;
  }
}

export default async function assertOk(response: Response): Promise<Response> {
  if (!response.ok) {
    const body = response.text && (await response.text());
    throw new APIError(`Service returned status code ${response.status}. body: ${body}`, body);
  }
  return response;
}
