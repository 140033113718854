import React, { Component } from 'react';
import './styles.scss';

export default class Spinner extends Component {
  constructor(props) {
    super(props);
    const color = this.props.color || '#3e4c75';
    const rgbaColor = this._convertHexColorToRgba(color);
    const thickness = this.props.thickness || '10px';
    const border = `${thickness} solid ${rgbaColor}`;
    this.state = {
      color,
      border,
      width: 0,
      height: 0,
      spinnerContainerClassName: ''
    };
  }

  componentDidMount() {
    // Set these properties in componentDidMount instead of in the constructor to fix an annoying issue
    // where react-snapshot doesn't apply these properties correctly.
    this.setState({
      width: this.props.size || '100%',
      height: this.props.size || '100%',
      spinnerContainerClassName: 'spinner-container'
    });
  }

  render() {
    return (
      <div
        className={`${this.state.spinnerContainerClassName} ${this.props.className || ''}`}
        style={{ width: this.state.width, height: this.state.height }}
      >
        <div className="spinner" style={{ border: this.state.border, borderLeftColor: this.state.color }} />
      </div>
    );
  }

  _convertHexColorToRgba(hexCode) {
    if (hexCode[0] !== '#') {
      throw new Error('Invalid hex color code: ' + hexCode);
    }
    const red = parseInt(hexCode.slice(1, 3), 16);
    const green = parseInt(hexCode.slice(3, 5), 16);
    const blue = parseInt(hexCode.slice(5, 7), 16);
    return `rgba(${red}, ${green}, ${blue}, 0.2)`;
  }
}
